
import React, { useState } from "react";
import { Tooltip, checkStringInArray, createLogo, months } from "../Components/Utils";
import { useLocation, useNavigate } from "react-router-dom";
import * as globalApi from "../../api/global.api";
import defaultPointer from "../../assets/svg-new/11 Hand pointing.svg";
import measurementSvg from "../../assets/svg-new/Group 52467.svg";
import globe from "../../assets/svg-new/Path 4.svg";
import layerimg from "../../assets/svg-new/Path 24511.svg";
import dashboardimg from "../../assets/svg-new/Group 52431.svg";
import reportimg from "../../assets/svg-new/pie-chart.svg";
import downloadimg from "../../assets/svg-new/surface1.svg";
import lineimg from "../../assets/svg-new/Path 24856.svg";
import polyimg from "../../assets/svg-new/pentagon.svg";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
} from "reactstrap";
import { useSelector } from "react-redux";

const LeftPanal = (props) => {
  const loc = useLocation().state;
  const history = useNavigate();
  const client = props?.project?.client;
  const features = useSelector((state) => state?.user?.features);

  const [isReportsDropdown, setIsReportsDropDown] = useState(false);
  const [isDownloadsDropdown, setIsDownloadsDropDown] = useState(false);
  const [isLayersDropdown, setIsLayersDropDown] = useState(false);
  const [isMeasuringDropdown, setIsMeasuringDropDown] = useState(false);

  const toggleReportsDropdown = () => {
    setIsMeasuringDropDown(false);
    setIsReportsDropDown(!isReportsDropdown);
  };

  const toggleDownloadsDropdown = () => {
    setIsMeasuringDropDown(false);
    setIsDownloadsDropDown(!isDownloadsDropdown);
  };

  const toggleLayersDropdown = () => {
    setIsMeasuringDropDown(false);
    setIsLayersDropDown(!isLayersDropdown);
  };

  const toggleMeasuringDropdown = () => {
    setIsMeasuringDropDown(false);
    props.setTool("none");
    props.setAccessory("none");
  };

  const handleDownload = (type, report) => {
    props?.setLoading(true);
    let url = "mergepdf";
    let params = [report?.id];
    let name = report?.deliverableName?.replace(".pdf", "");
    let date = new Date();
    let finalDate =
      name +
      "-" +
      date.getDate() +
      "-" +
      months[date.getMonth()] +
      "-" +
      date.getFullYear();

    globalApi
      .getBlob(url, params)
      .then((data) => {
        if (data) {
          prepareBlob(data?.data, type, finalDate);
          props?.setLoading(false);
        }
      })
      .catch((err) => {
        props?.setLoading(false);
      });
  };

  const prepareBlob = (data, type, name) => {
    var a = document.createElement("a");
    var file = new Blob([data], {
      type: "application/pdf",
    });
    var fileURL = URL.createObjectURL(file);
    a.href = fileURL;
    a.target = "_blank";
    if (type === "view") {
      window.open(fileURL, "_blank");
    }
    if (type === "download") {
      a.download = `${name}.pdf`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  const handleDownloadDeliverables = (opt) => {
    var a = document.createElement("a");
    a.href = opt.key;
    a.download = "true";
    a.target = "_blank";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const makeLayerDropdown = (layer) => {
    return (
      <div className="mb-2 layer-dropdown pe-3">
        <div className="fs-13">{layer?.displayname}</div>
        <div>
          <Input
            type="checkbox"
            checked={layer?.visibility}
            onChange={() => props?.changeVisibilty(layer)}
            disabled={
              !checkStringInArray(
                "Manage layer visibility - Mining Survey",
                features
              )
            }
          />
        </div>
      </div>
    );
  };

  return (
    <div className="left-panal">
      {/* Client Logo starts */}
      <div
        className="client-logo pointer"
        onClick={() => history("/client-dashboard")}
      >
        {client?.clientLogo ? (
          <img src={client?.clientLogo} alt="" />
        ) : (
          <div className="avatar">
            <span>{createLogo(client?.clientName)}</span>
          </div>
        )}
      </div>
      {/* Client Logo end*/}

      {/* Dashboard start*/}
      <img
        src={dashboardimg}
        id="dashboard"
        alt="dashboard"
        onClick={() => history("/survey-project-dashboard", { state: loc })}
        className="mb-4 pointer"
      />
      {Tooltip("dashboard", "Dashboard", "right")}
      {/* Dashboard end*/}

      {/* Reports starts*/}
      {checkStringInArray("View reports - Mining Survey", features) && (
        <Dropdown
          className="card-header-dropdown"
          isOpen={isReportsDropdown}
          toggle={toggleReportsDropdown}
          direction="end"
        >
          <DropdownToggle
            tag="a"
            className="text-reset dropdown-btn"
            role="button"
          >
            <img
              src={reportimg}
              id="reports"
              alt="reports"
              className="mb-4 pointer"
            />
            {Tooltip("reports", "Reports", "right")}
          </DropdownToggle>
          <DropdownMenu className="tools-menu-end p-0">
            <Card className="p-0 mb-0 br-0 brd-none">
              <CardHeader className="d-flex align-items-center justify-content-between bg-success br-0 m-0">
                <div className="text-light fs-6">Reports</div>
                <i
                  className="bx bx-x fs-4 pointer text-light"
                  onClick={toggleReportsDropdown}
                ></i>
              </CardHeader>
              <CardBody className="p-2 max-height-100">
                {props.reports?.length > 0 ? (
                  <Row>
                    {props?.reports?.map((report, id) => (
                      <React.Fragment key={id}>
                        <Col
                          xs={12}
                          className="fs-13 d-flex justify-content-between align-items-center"
                        >
                          <div>{report?.deliverableName}</div>
                          <div>
                            <i
                              className="bx bx-show fs-5 pointer me-2"
                              onClick={() => handleDownload("view", report)}
                            ></i>
                            <i
                              className="bx bx-download fs-5 pointer"
                              onClick={() => handleDownload("download", report)}
                            ></i>
                          </div>
                        </Col>
                      </React.Fragment>
                    ))}
                  </Row>
                ) : (
                  <div className="text-center">No Reports available </div>
                )}
              </CardBody>
            </Card>
          </DropdownMenu>
        </Dropdown>
      )}

      {/* Reports ends*/}

      {/* Downloads starts */}
      {checkStringInArray("Download reports - Mining Survey", features) && (
        <Dropdown
          className="card-header-dropdown"
          isOpen={isDownloadsDropdown}
          toggle={toggleDownloadsDropdown}
          direction="end"
        >
          <DropdownToggle
            tag="a"
            className="text-reset dropdown-btn"
            role="button"
          >
            <img
              src={downloadimg}
              id="downloads"
              alt="downloads"
              className="mb-4 pointer"
            />
            {Tooltip("downloads", "Downloads", "right")}
          </DropdownToggle>
          <DropdownMenu className="tools-menu-end p-0">
            <Card className="p-0 mb-0 br-0 brd-none">
              <CardHeader className="d-flex align-items-center justify-content-between bg-success br-0 m-0">
                <div className="text-light fs-6">Downloads</div>
                <i
                  className="bx bx-x fs-4 pointer text-light"
                  onClick={toggleDownloadsDropdown}
                ></i>
              </CardHeader>
              <CardBody className="p-2 max-height-100">
                {props.downloads?.length > 0 ? (
                  <Row>
                    {props?.downloads?.map((download, id) => (
                      <Col
                        xs={12}
                        key={id}
                        className="fs-13 d-flex justify-content-between align-items-center"
                      >
                        <div>{download?.deliverableName}</div>
                        <i
                          className="bx bx-download fs-5 pointer"
                          onClick={() => handleDownloadDeliverables(download)}
                        ></i>
                      </Col>
                    ))}
                  </Row>
                ) : (
                  <div className="text-center">No Downloads available</div>
                )}
              </CardBody>
            </Card>
          </DropdownMenu>
        </Dropdown>
      )}

      {/* Downloads ends */}

      {/* Layers start*/}
      <Dropdown
        className="card-header-dropdown"
        isOpen={isLayersDropdown}
        toggle={toggleLayersDropdown}
        direction="end"
      >
        <DropdownToggle
          tag="a"
          className="text-reset dropdown-btn"
          role="button"
        >
          <img
            src={layerimg}
            id="layers"
            alt="layers"
            className="mb-4 pointer"
          />
          {Tooltip("layers", "Layers", "right")}
        </DropdownToggle>
        <DropdownMenu className="tools-menu-end p-0">
          <Card className="p-0 mb-0 br-0 brd-none">
            <CardHeader className="d-flex align-items-center justify-content-between bg-success br-0 m-0">
              <div className="text-light fs-6">Layers</div>
              <i
                className="bx bx-x fs-4 pointer text-light"
                onClick={toggleLayersDropdown}
              ></i>
            </CardHeader>
            <CardBody className="p-2">
              {props.layers?.length > 0 ? (
                <Row>
                  {props?.layers?.map((layer, key) => (
                    <Col key={key} sm={12}>
                      {makeLayerDropdown(layer)}
                    </Col>
                  ))}
                </Row>
              ) : (
                <div className="text-center">No Layers available</div>
              )}
            </CardBody>
          </Card>
        </DropdownMenu>
      </Dropdown>
      {/* Layers ends*/}

      {/* Satellite starts*/}
      {checkStringInArray("Manage map style - Mining Survey", features) && (
        <>
          <img
            src={globe}
            className="mb-4 pointer"
            alt="satellite"
            onClick={() => props?.setAccessory("Satellite")}
            id="satellite"
          />
          {Tooltip("satellite", "Satellite", "right")}
        </>
      )}
      {/* Satellite ends*/}

      <span className="mb-4 fs-12">Tools</span>

      {/* Measuring tools start*/}
      {checkStringInArray(
        "Measure tools with live measurements - Mining Survey",
        features
      ) && (
        <>
          <img
            src={measurementSvg}
            alt="measurement tool"
            id="measuring"
            onClick={() => props?.setAccessory("Measure")}
            className="pointer mb-4"
          />

          {Tooltip("measuring", "Measuring Tools", "right")}
        </>
      )}
      {props?.accessory === "Measure" && (
        <Card className="p-0 mb-0 measuretools">
          <CardHeader className="py-1 ps-3  d-flex align-items-center justify-content-between">
            <div className="fs-16 text-mos">Select Tools</div>
            <i
              className="bx bx-x fs-4 pointer"
              onClick={toggleMeasuringDropdown}
            ></i>
          </CardHeader>
          <CardBody className="d-flex align-items-center p-3 ">
            <div>
              <img
                src={lineimg}
                className="pointer me-3 mb-3 ps-2"
                onClick={() => props?.setTool("LineString")}
              />
              <div className="fs-12 text-primary">Distance</div>
            </div>
            <div>
              <img
                src={polyimg}
                className="pointer me-3 mb-3 ps-3"
                onClick={() => props?.setTool("Polygon")}
              />
              <div className="fs-12 text-center text-primary"> Area</div>
            </div>
          </CardBody>
          <CardFooter>
            <div className="fs-15 text-muted text-center">
              Measurement Result
            </div>
            <div className="text-center text-measure">
              {props?.measurement ? props?.measurement : "- - - -"}
            </div>
          </CardFooter>
        </Card>
      )}

      {/* Measuring tools ends*/}

      {/* Default Pointer */}
      <img
        src={defaultPointer}
        alt="default pointer"
        className="pointer"
        id="pointer"
        onClick={() => props?.setTool("none")}
      />
      {Tooltip("pointer", "Default Pointer", "right")}
    </div>
  );
};

export default LeftPanal;
