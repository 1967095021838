import React, { useEffect, useRef, useState } from "react";
import ClientHeader from "../Dashboard/ClientHeader";
import Select from "react-select";
import { Tooltip } from "../Components/Utils";
import { useLocation, useNavigate } from "react-router-dom";
import * as api from "../../api/global.api";
import drogoLogo from "../../assets/svg-new/drogo_light_2.png";

const ThreeDTool = (props) => {
  const navigate = useNavigate();
  const location = JSON.parse(localStorage.getItem("3dsurveydata"));
  const { id, projectId } = location;
  const cesiumContainerRef = useRef(null);
  const [isReset, setIsReset] = useState(false);
  const [source3dOptions, setSource3dOptions] = useState([]);

  const [selected, setSelected] = useState(null);

  useEffect(() => {
    get3DSources();
  }, []);

  const get3DSources = async () => {
    let response = await api.get(
      `source3d/get?surveyId=${id}&projectId=${projectId}`
    );
    if (response?.status === "Success") {
      let options = response?.response?.findSource3d?.map((tile) => ({
        label: tile?.fileName,
        value: process.env.REACT_APP_API_URL + tile?.fileLink,
      }));
      setSource3dOptions(options);
      setSelected(options[0]);
    } else {
      toast.error(response?.errormessage);
    }
  };

  useEffect(() => {
    if (!window.Cesium) {
      console.error("CesiumJS not loaded");
      return;
    }

    window.Cesium.Ion.defaultAccessToken = null;
    const CartoAttribution =
      'Map tiles by <a href="https://carto.com">Carto</a>, under CC BY 3.0. Data by <a href="https://www.openstreetmap.org/">OpenStreetMap</a>, under ODbL.';

    const imageryViewModels = [
      new window.Cesium.ProviderViewModel({
        name: "OpenStreetMap",
        iconUrl: window.Cesium.buildModuleUrl(
          "Widgets/Images/ImageryProviders/openStreetMap.png"
        ),
        tooltip:
          "OpenStreetMap (OSM) is a collaborative project to create a free editable map of the world.\nhttp://www.openstreetmap.org",
        creationFunction: () =>
          new window.Cesium.UrlTemplateImageryProvider({
            url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
            subdomains: "abc",
            minimumLevel: 0,
            maximumLevel: 19,
          }),
      }),
      new window.Cesium.ProviderViewModel({
        name: "Positron",
        tooltip: "CartoDB Positron basemap",
        iconUrl: "http://a.basemaps.cartocdn.com/light_all/5/15/12.png",
        creationFunction: () =>
          new window.Cesium.UrlTemplateImageryProvider({
            url: "http://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png",
            credit: CartoAttribution,
            minimumLevel: 0,
            maximumLevel: 18,
          }),
      }),
      new window.Cesium.ProviderViewModel({
        name: "Positron without labels",
        tooltip: "CartoDB Positron without labels basemap",
        iconUrl:
          "http://a.basemaps.cartocdn.com/rastertiles/light_nolabels/5/15/12.png",
        creationFunction: () =>
          new window.Cesium.UrlTemplateImageryProvider({
            url: "https://{s}.basemaps.cartocdn.com/rastertiles/light_nolabels/{z}/{x}/{y}.png",
            credit: CartoAttribution,
            minimumLevel: 0,
            maximumLevel: 18,
          }),
      }),
      new window.Cesium.ProviderViewModel({
        name: "Dark Matter",
        tooltip: "CartoDB Dark Matter basemap",
        iconUrl:
          "http://a.basemaps.cartocdn.com/rastertiles/dark_all/5/15/12.png",
        creationFunction: () =>
          new window.Cesium.UrlTemplateImageryProvider({
            url: "https://{s}.basemaps.cartocdn.com/rastertiles/dark_all/{z}/{x}/{y}.png",
            credit: CartoAttribution,
            minimumLevel: 0,
            maximumLevel: 18,
          }),
      }),
      new window.Cesium.ProviderViewModel({
        name: "Dark Matter without labels",
        tooltip: "CartoDB Dark Matter without labels basemap",
        iconUrl:
          "http://a.basemaps.cartocdn.com/rastertiles/dark_nolabels/5/15/12.png",
        creationFunction: () =>
          new window.Cesium.UrlTemplateImageryProvider({
            url: "https://{s}.basemaps.cartocdn.com/rastertiles/dark_nolabels/{z}/{x}/{y}.png",
            credit: CartoAttribution,
            minimumLevel: 0,
            maximumLevel: 18,
          }),
      }),
      new window.Cesium.ProviderViewModel({
        name: "Voyager",
        tooltip: "CartoDB Voyager basemap",
        iconUrl:
          "http://a.basemaps.cartocdn.com/rastertiles/voyager_labels_under/5/15/12.png",
        creationFunction: () =>
          new window.Cesium.UrlTemplateImageryProvider({
            url: "https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}.png",
            credit: CartoAttribution,
            minimumLevel: 0,
            maximumLevel: 18,
          }),
      }),
      new window.Cesium.ProviderViewModel({
        name: "Voyager without labels",
        tooltip: "CartoDB Voyager without labels basemap",
        iconUrl:
          "http://a.basemaps.cartocdn.com/rastertiles/voyager_nolabels/5/15/12.png",
        creationFunction: () =>
          new window.Cesium.UrlTemplateImageryProvider({
            url: "https://{s}.basemaps.cartocdn.com/rastertiles/voyager_nolabels/{z}/{x}/{y}.png",
            credit: CartoAttribution,
            minimumLevel: 0,
            maximumLevel: 18,
          }),
      }),
      new window.Cesium.ProviderViewModel({
        name: "National Map Satellite",
        iconUrl:
          "https://basemap.nationalmap.gov/arcgis/rest/services/USGSImageryOnly/MapServer/tile/4/6/4",
        creationFunction: () =>
          new window.Cesium.UrlTemplateImageryProvider({
            url: "https://basemap.nationalmap.gov/arcgis/rest/services/USGSImageryOnly/MapServer/tile/{z}/{y}/{x}",
            credit:
              'Tile data from <a href="https://basemap.nationalmap.gov/">USGS</a>',
            minimumLevel: 0,
            maximumLevel: 16,
          }),
      }),
    ];

    const viewer = new window.Cesium.Viewer(cesiumContainerRef.current, {
      // imageryProviderViewModels: imageryViewModels,
      // selectedImageryProviderViewModel: imageryViewModels[1],
      animation: false,
      timeline: false,
      infoBox: false,
      homeButton: false,
      fullscreenButton: true,
      selectionIndicator: false,
      scene3DOnly: false,
      vrButton: false,
      sceneModePicker: false,
    });

    viewer.baseLayerPicker.viewModel.terrainProviderViewModels.removeAll();

    const tileset = new window.Cesium.Cesium3DTileset({
      url: selected?.value,
      maximumScreenSpaceError: 1,
    });

    tileset.readyPromise
      .then(function (tileset) {
        // Set initial camera view
        viewer.zoomTo(tileset);
      })
      .otherwise(function (error) {
        console.error("Failed to load tileset:", error);
      });

    viewer.scene.primitives.add(tileset);

    viewer.zoomTo(tileset);
    setIsReset(false);

    return () => {
      if (viewer) {
        viewer.destroy();
      }
    };
  }, [selected, isReset === true]);

  const selectChange = (e) => {
    setSelected(e);
  };

  const reset = () => {
    setIsReset(true);
  };

  return (
    <div>
      <div className="client-header" style={{height: "70px"}}>
        <div className="drogo-logo">
          <img src={drogoLogo} alt="drogo-logo" width="100%" />
        </div>
      </div>
      <div
        id="cesiumContainer"
        ref={cesiumContainerRef}
        style={{ width: "100%", height: "100vh" }}
      ></div>
      <div className="xsd">
        {/* <i
          class="bx bx-arrow-back fs-2 text-light me-3"
          id="back"
          onClick={() => navigate(-1)}
        ></i>
        {Tooltip("back", "Back", "bottom")} */}
        <Select
          options={source3dOptions}
          width="100"
          value={selected}
          onChange={(val) => selectChange(val)}
        />
      </div>
      <div className="btn-3d fs-3 pointer">
        <i class="bx bx-reset" onClick={reset} id="reset"></i>
        {Tooltip("reset", "Reset", "bottom")}
      </div>
    </div>
  );
};

export default ThreeDTool;
