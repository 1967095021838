import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../commons/functions";
import { Tooltip } from "primereact/tooltip";
import downloadImg from "../../assets/svg-new/file-download (1) 1.svg";

const SourceTable = ({
  data,
  handleDownload,
  handleEdit,
  setOpen,
  handleDelete,
  setMode,
}) => {
  const navigate = useNavigate();
  const columns = [
    {
      name: <span className="font-weight-bold fs-13">Corridor</span>,
      selector: (row) => row.corridorName,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Line Name</span>,
      sortable: true,
      selector: (row) => row.lineName,
    },
    {
      name: <span className="font-weight-bold fs-13">Corridor Length</span>,
      selector: (row) => row.corridorLength,
      sortable: true,
      grow: 0.5,
    },
    {
      name: <span className="font-weight-bold fs-13">Starting Position</span>,
      selector: (row) => {
        let lat = row?.startingPosition?.split(",")[0];
        let long = row?.startingPosition?.split(",")[1];
        return (
          <span>
            {lat} <span className="fs-18">, </span> {long}
          </span>
        );
      },
      grow: 2,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Ending position</span>,
      selector: (row) => {
        let lat = row?.endingPosition?.split(",")[0];
        let long = row?.endingPosition?.split(",")[1];
        return (
          <span>
            {lat} <span className="fs-18">, </span> {long}
          </span>
        );
      },
      grow: 2,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Number of Towers</span>,
      selector: (row) => row.noOfTowers,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">SME</span>,
      selector: (row) => row.sme,
      sortable: true,
    },
    // {
    //   name: <span className="font-weight-bold fs-13">Client Users</span>,
    //   selector: (row) => row.clientUser,
    //   sortable: true,
    // },
    {
      name: <span className="font-weight-bold fs-13">City</span>,
      selector: (row) => row.address,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Creation Date</span>,
      selector: (row) => formatDate(row.createdAt),
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Status</span>,
      selector: (row) => row.corridorStatus,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Actions</span>,
      sortable: true,

      cell: (row) => {
        return (
          <span>
            <Tooltip target=".custom-target-icon-delete" />
            <Tooltip target=".custom-target-icon-edit" />
            <Tooltip target=".custom-target-icon-source" />
            <i
              className="mdi mdi-transmission-tower fs-4 me-2 pointer custom-target-icon-source"
              data-pr-tooltip="Towers"
              data-pr-position="top"
              style={{ color: "green" }}
              onClick={() => {
                navigate(`/delivers/source/corridor/tower/${row.id}`);
                sessionStorage.setItem("corridorName", row.corridorName);
              }}
            ></i>
            {/* download icon */}
            <img
              className="text-primary fs-4 me-2 pointer custom-target-icon-download"
              src={downloadImg}
              alt="Download"
              onClick={() => handleDownload(row)}
            />
            <i
              className="bx bx-edit fs-4 me-2 pointer custom-target-icon-edit"
              data-pr-tooltip="Edit"
              data-pr-position="top"
              style={{ color: "green" }}
              onClick={() => {
                handleEdit(row);
                setMode("Edit Corridor");
                setOpen(true);
              }}
            ></i>
            <i
              className="ri-delete-bin-6-line fs-4 me-2 pointer custom-target-icon-delete"
              data-pr-tooltip="Delete"
              data-pr-position="top"
              style={{ color: "red" }}
              onClick={() => {
                handleDelete(row);
              }}
            ></i>
          </span>
        );
      },
    },
  ];
  return <DataTable columns={columns} data={data} />;
};

export default SourceTable;
