import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import Select from "react-select";
import { KML, GeoJSON } from "ol/format";
import { transform } from "ol/proj";
import { Polygon, LineString, Point } from "ol/geom.js";
import Feature from "ol/Feature";
import { toast } from "react-toastify";
import {
  downloadExistingLayer,
  downloadSHPLayer,
} from "../../api/dashboard.api";


const ExportModal = (props) => {
  const { show, closeModal, modalheader } = props;
  const [format, setFormat] = useState(null);
  const [layer, setLayer] = useState(null);
  const [options, setOptions] = useState([]);

  const handleModalBody = () => {
    return (
      <Form>
        <div className="mb-2">
          <Label className="maditory fs-14">
            Layer <span className="required">*</span>
          </Label>
          <Select
            placeholder="Please select layer to export"
            options={options}
            value={layer}
            onChange={(val) => setLayer(val)}
          />
        </div>
        <div className="mb-2">
          <Label className="fs-14">
            Format <span className="required">*</span>
          </Label>
          <Select
            value={format}
            options={[
              { label: ".kml", value: ".kml" },
              { label: ".shp", value: ".shp" },
            ]}
            onChange={(val) => setFormat(val)}
          />
        </div>
      </Form>
    );
  };

  const handledisabled = () => {
    if (layer === null || format === null) {
      return true;
    } else {
      return false;
    }
  };

  const handleDownload = () => {
    if (layer.type === "vector") {
      if (format.value === ".kml") {
        downloadExistingLayerKML(layer.value, format.value);
      } else {
        downloadExistingLayerSHP(layer.value, format.value);
      }
    } else {
      if (format.value === ".kml") {
        downloadKML(layer);
      } else {
        downloadSHP(props?.exportLayer, layer);
      }
    }
    closeModal();
  };

  function downloadKML(lData) {
    let exportLayer = props?.exportLayer;

    const features = exportLayer[lData.idx].getSource().getFeatures();
    const transformedFeatures = features.map((feature) => {
      const geometry = feature.getGeometry();
      const transformedGeometry = geometry.transform("EPSG:3857", "EPSG:4326");
      const transformedFeature = feature.clone();
      transformedFeature.setGeometry(transformedGeometry);
      return transformedFeature;
    });
    const kmlFormat = new KML();
    const kmlData = kmlFormat.writeFeatures(transformedFeatures);

    // Create a Blob with the KML data
    const blob = new Blob([kmlData], {
      type: "application/vnd.google-earth.kml+xml",
    });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `${lData.label}.kml`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    toast.success("Downloaded succesfully ");
  }

  const downloadSHP = async (exportLayers, lData) => {
    try {
      const modifiedGeometry = exportLayers[lData.idx]
        .getSource()
        .getFeatures()[0]
        .getGeometry();

      const geometryType = modifiedGeometry.getType();
      let geoData;
      if (geometryType === "Polygon") {
        // Transform polygon coordinates to EPSG:4326
        let convertedPolygonCoordinates = modifiedGeometry
          .getCoordinates()
          .map(function (ringCoords) {
            return ringCoords.map(function (coord) {
              return transform(coord, "EPSG:3857", "EPSG:4326");
            });
          });

        // Create an OpenLayers Polygon feature
        var olPolygon = new Polygon(convertedPolygonCoordinates);
        var feature = new Feature(olPolygon);

        // Convert feature geometry to GeoJSON
        let geojsonFormat = new GeoJSON();
        geoData = geojsonFormat.writeFeature(feature);
      } else if (geometryType === "LineString") {
        // Transform LineString coordinates to EPSG:4326
        var convertedCoordinates = modifiedGeometry
          .getCoordinates()
          .map(function (coord) {
            return transform(coord, "EPSG:3857", "EPSG:4326");
          });

        // Create an OpenLayers LineString feature
        var olLine = new LineString(convertedCoordinates);
        var featureLine = new Feature(olLine);

        // Convert feature geometry to GeoJSON
        let geojsonFormat = new GeoJSON();
        geoData = geojsonFormat.writeFeature(featureLine);
      } else if (geometryType === "Point") {
        // Transform point coordinates to EPSG:4326
        let convertedCoordinates = transform(
          modifiedGeometry.getCoordinates(),
          "EPSG:3857",
          "EPSG:4326"
        );

        // Create an OpenLayers point feature
        var olPoint = new Point(convertedCoordinates);
        var featurePoint = new Feature(olPoint);

        // Convert feature geometry to GeoJSON
        let geojsonFormat = new GeoJSON();
        geoData = geojsonFormat.writeFeature(featurePoint);
      }

      // Create the request object with the GeoJSON data
      const req = {
        type: "Feature Collection",
        features: [JSON.parse(geoData)],
      };

      // Send the request to the server
      const shpBlob = await downloadSHPLayer(req);
      if (shpBlob) {
        const uint8Array = new Uint8Array(shpBlob.buffer.data);
        const blob = new Blob([uint8Array], {
          type: "application/octet-stream",
        });

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = `${lData.label}.zip`;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        toast.success("Downloaded successfully");
      } else {
        toast.error("Failed to download");
      }
    } catch (error) {
      toast.error("An error occurred during download");
    }
  };

  const downloadExistingLayerKML = async (lData, format) => {
    const req = {
      layerName: lData.trim(),
      fileType: format,
    };
    // Send the request to the server
    const kmlBlob = await downloadExistingLayer(req);

    if (kmlBlob) {
      const url = kmlBlob.url;
      const link = document.createElement("a");
      link.href = url;

      link.download = `${lData}.kml`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      toast.success("Downloaded successfully");
    } else {
      toast.error("Failed to download");
    }
  };

  const downloadExistingLayerSHP = async (lData, format) => {
    try {
      const req = {
        layerName: lData.trim(),
        fileType: format,
      };
      // Send the request to the server
      const shpBlob = await downloadExistingLayer(req);
      if (shpBlob) {
        const url = shpBlob.data.url;
        const link = document.createElement("a");
        link.href = url;
        link.download = `${lData}.zip`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        toast.success("Downloaded successfully");
      } else {
        toast.error("Failed to download");
      }
    } catch (error) {
      toast.error("An error occurred during download");
    }
  };

  useEffect(() => {
    // async function to fetch displayValueNames for each option
    async function fetchDisplayValueNames() {
      if (props.filteredLayer) {
        const updatedOptions = await Promise.all(
          props.filteredLayer.map(async (ele, key) => {
            const label = ele.displayname || ele.sourcename;
            const value = ele.displayname || ele.sourcename;
            const idx = key;
            const type = ele.type || "";
            // Check conditions to determine the label
            let dynamicLabel = label;
            // if (type === "vector") {
            //   let response = await api.getReq(
            //     `getsourcebylayername/?sourceName=${label}`
            //   );

            //   dynamicLabel =
            //     response.data.response.findSources.sourceDisplayName;
            // } else {
            //   dynamicLabel = ele.displayname;
            // }

            return {
              label: dynamicLabel,
              value,
              idx,
              type,
            };
          })
        );
        setOptions(updatedOptions);
      }
    }
    fetchDisplayValueNames();
  }, [props.filteredLayer]);

  return (
    <div className="d-flex align-item-end">
      <Modal
        isOpen={show}
        toggle={() => closeModal()}
        size="sm"
        className="bg-custom-white toolModal"
        backdrop={false}
      >
        <ModalHeader
          className={`border-bottom p-2 bg-light`}
          toggle={() => closeModal()}
        >
          <span className="text-dark text-bold fs-15">{modalheader}</span>
        </ModalHeader>
        <ModalBody>{handleModalBody()}</ModalBody>
        <ModalFooter>
          <div className="d-flex gap-2 justify-content-end mt-3 mb-2">
            <Button
              outline
              className="w-sm"
              color="primary"
              data-bs-dismiss="modal"
              onClick={() => closeModal()}
            >
              Cancel
            </Button>

            <Button
              type="button"
              className="bg-primary w-sm"
              disabled={handledisabled()}
              onClick={handleDownload}
            >
              Save
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ExportModal;
