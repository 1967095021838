import { Calendar } from "primereact/calendar";
import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Input,
  Row,
} from "reactstrap";
import {
  DefectCharts,
  GroupesCharts,
  HealthCharts,
  ObservationCountCharts,
} from "./WidgetsCharts";
import * as api from "../../api/rest.api";
import { convertDateToISO } from "../Components/Utils";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import { useAsyncDebounce } from "react-table";

const TxCharts = ({ props, project }) => {
  const navigate = useNavigate();
  const [defectData, setDefectData] = useState({});
  const [obsData, setObsData] = useState([]);
  const [obsValue, setObsValue] = useState([]);
  const [healthData, setHealthData] = useState([]);
  const [health, setHealth] = useState(null);
  const [statusOptions, setStatusOptions] = useState([]);
  const [selected, setSelected] = useState(null);
  const [dateVal, setDateVal] = useState([]);
  const fp = useRef(null);
  const [healthSeries, setHelathSeries] = useState([]);
  const [color, setColor] = useState(null);

  const getCharts = async () => {
    let url = `clientDashboardByProject?projectId=${project?.id}`;
    if (selected) {
      url = url + `&componentStatus=${selected?.value}`;
    }
    if (dateVal?.length === 2) {
      const date1 = convertDateToISO(dateVal[0]);
      const date2 = convertDateToISO(dateVal[1]);
      url =
        url +
        `&actionStartDate=${date1}&actionEndDate=${date2}&observationStartDate=${date1}&observationEndDate=${date2}&severityClosedStartDate=${date1}&severityClosedEndDate=${date2}`;
    }
    let res = await api.getData(url);
    let towerActionStatus = res?.data?.towerActionStatus?.filter(
      (e) => e?.severityStatus !== "null" && e?.severityStatus !== "Good"
    );

    console.log(towerActionStatus);
    let category = towerActionStatus?.map((e) => e?.severityStatus);
    let openvalues = towerActionStatus?.map((e) => e?.Open);
    let closevalues = towerActionStatus?.map((e) => e?.Closed);
    setDefectData({ category, openvalues, closevalues });
    let towerStatusDetails = res?.data?.towerStatusDetails;
    delete towerStatusDetails?.null;
    let health = Object?.values(towerStatusDetails);
    let health1 = Object.keys(towerStatusDetails);
    setHelathSeries(health1);
    let dataColor = health1?.map((val) => {
      val = val?.toLowerCase();
      switch (val) {
        case "high risk":
          return '"#ff0202"';
        case "low risk":
          return '"#086add"';
        case "medium risk":
          return '"#ffff00"';
        case "good":
          return '"#3fc304"';
        case "major":
          return '"#bb2626"';
        case "minor":
          return '"#ffff00"';
        default:
          return '"#000000"';
      }
    });
    setHealthData(health || []);
    setColor("[" + dataColor?.join(", ") + "]");

    const componenetStatus = res?.data?.componentStatus || {};
    const dynamicStatusOptions = Object.values(componenetStatus).map(
      (status) => ({
        label: status,
        value: status,
      })
    );
    setStatusOptions(dynamicStatusOptions);

    let obs = res?.data?.observationDetails;
    let series = obs?.map((e) => e.observation);
    let value = obs?.map((e) => e.count);
    setObsData(series);
    setObsValue(value);
  };

  const handleChartClick = (idx) => {
    if (idx !== "all") {
      let hels = healthLabels[idx];
      setHealth(hels);
    } else {
      setHealth(idx);
    }
  };

  useEffect(() => {
    if (health) {
      navigate("/client-tx-towers", { state: { project, health: health } });
    }
  }, [health]);

  useEffect(() => {
    getCharts();
  }, [selected, dateVal]);

  const colors = ["#FF0202", "#086ADD", "#3FC304", "#EBC505"];

  return (
    <div className="">
      <Row className="mx-3">
        <Col className="fs-18 fw-500">OverView</Col>
        <Col>
          <div className="d-flex justify-content-end">
            <Select
              className="me-3 w-50"
              placeholder="Tower Observation Status"
              options={statusOptions}
              onChange={(selectedOption) => setSelected(selectedOption)}
              isClearable={true}
            />
            <div className={"form-icon flex-nowrap width-300"}>
              <Flatpickr
                className="form-control"
                ref={fp}
                value={dateVal}
                placeholder={"Select by Date Range"}
                options={{
                  mode: "range",
                  dateFormat: "d M, Y",
                  defaultDate: dateVal,
                  maxDate: new Date(),
                }}
                onChange={(e) => {
                  setDateVal(e);
                }}
                isClearable={true}
              />
              {/* <i className="ri-calendar-2-line" /> */}
            </div>
          </div>
        </Col>
      </Row>

      <Row className="mx-3">
        <Col lg={6}>
          <Card>
            <CardHeader>
              <h4 className="card-title d-flex justify-content-between mb-0">
                <div>Project Level Tower Health Status</div>
                <div
                  className="pointer underline text-low"
                  onClick={() => handleChartClick("all")}
                >
                  View All
                </div>
              </h4>
            </CardHeader>

            <CardBody>
              <HealthCharts
                data={healthData}
                healthLabels={healthSeries}
                dataColors={
                  color ? color : '["#FF0202", "#086ADD", "#3FC304", "#EBC505"]'
                }
                selectedIdx={(idx) => {
                  handleChartClick(idx);
                }}
              />
            </CardBody>

            <CardFooter>
              <Row>
                {healthSeries?.map((e, idx) => (
                  <Col key={idx}>
                    <i
                      className="bx bxs-circle"
                      style={{
                        color: color ? JSON?.parse(color)[idx] : colors[idx],
                      }}
                    ></i>{" "}
                    {e} ({healthData[idx]})
                  </Col>
                ))}
              </Row>
            </CardFooter>
          </Card>
        </Col>

        <Col lg={6}>
          <Card>
            <CardHeader>
              <h4 className="card-title mb-0">
                Defect Status At Project Level
              </h4>
            </CardHeader>
            <CardBody>
              <GroupesCharts
                dataColors='[ "#CED4DA", "#29EDAB"]'
                data={defectData}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row className="mx-3">
        <Col lg={12}>
          <Card>
            <CardHeader>
              <h4 className="card-title d-flex justify-content-between mb-0">
                Count of Observation at Project Level
              </h4>
            </CardHeader>

            <CardBody>
              <ObservationCountCharts
                dataColors='["#4C9DE3", "#EF7D34", "#3FE3AC", "#D9E352", "#60A0C1", "#9191F5", "#F151FC", "#FCBA51"]'
                data={obsData}
                values={obsValue}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default TxCharts;
