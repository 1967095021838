import React, { useEffect, useState } from "react";
import ClientHeader from "./ClientHeader";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Common/Loader";
import * as api from "../../api/global.api";
import { toast } from "react-toastify";
import { Col, Row } from "reactstrap";
import ProjectCardTx from "../ClientTx/ProjectCard";
import ProjectCardOil from "../ClientOil/ProjectCard";
import ProjectCardSurvey from "../ClientSurvey/ProjectCard";
import { projectsArrTxn } from "../../store/projectsArrayReducer";
import Select from "react-select";
import { Valid } from "../../store/reducer";

const ClientDashboard = () => {
  const user = useSelector((state) => state?.user);
  const dispatch = useDispatch();
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedCircle, setSelectedCircle] = useState(null);
  const [circles, setCircles] = useState([]);
  const [projectNames, setProjectNames] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);

  const getRecords = async (value, name) => {
    setLoading(true);

    let baseUrl = `projects/get?module=${user.selectedModule}&roleCode=${user.selectedRole}&userID=${user.id}`;

    if (name === "circle") {
      baseUrl += value
        ? selectedProject
          ? `&circle=${value}&projectName=${selectedProject.label}`
          : `&circle=${value}`
        : selectedProject
        ? `&projectName=${selectedProject.label}`
        : "";
    } else if (name === "projectName") {
      baseUrl += value
        ? selectedCircle
          ? `&circle=${selectedCircle.value}&projectName=${value}`
          : `&projectName=${value}`
        : selectedCircle
        ? `&circle=${selectedCircle.value}`
        : "";
    }

    try {
      const res = await api.get(baseUrl);

      if (res?.response) {
        setCircles(
          res.response.circles?.map((circle) => ({
            label: circle.circleName,
            value: circle.id,
          })) || []
        );

        const projectOptions =
          res.response.projectData?.map((project) => ({
            label: project.projectName,
            value: project.id,
          })) || [];
        setProjectNames(projectOptions);
        setRecords(res.response.projectData || []);
        dispatch(projectsArrTxn(res.response.projectData || []));
        const isSelectedFeatures = res?.response?.featuresData?.filter(
          (e) => e.isSelected
        );
        const features = isSelectedFeatures?.map((e) => e.featureName);
        dispatch(
          Valid({
            ...user,
            features,
          })
        );
      } else {
        setRecords([]);
        toast.error(res.errorMessage || "Failed to fetch records");
      }
    } catch (error) {
      setRecords([]);
      toast.error("An error occurred while fetching records");
    } finally {
      setLoading(false);
    }
  };

  const handleCircleChange = (selectedOption) => {
    setSelectedCircle(selectedOption);
    getRecords(selectedOption?.value, "circle");
  };

  const handleProjectChange = (selectedOption) => {
    setSelectedProject(selectedOption);
    getRecords(selectedOption?.label, "projectName");
  };

  useEffect(() => {
    getRecords();
  }, [user]);

  return (
    <React.Fragment>
      <ClientHeader />
      {loading && (
        <div className="loading">
          <Loader />
        </div>
      )}
      <div className="content">
        <Row className="m-3 d-flex justify-content-between align-items-center">
          <div className="fw-500 fs-20">Projects</div>
          <div className="ms-auto d-flex justify-content-end">
            <Select
              className="w-15 me-3"
              placeholder="Select Project"
              options={projectNames}
              value={selectedProject}
              onChange={handleProjectChange}
              isClearable={true}
            />

            <Select
              className="w-15"
              placeholder="Select Circle"
              options={circles}
              value={selectedCircle}
              onChange={handleCircleChange}
              isClearable={true}
            />
          </div>
        </Row>

        {records?.length > 0 ? (
          <Row className="mx-3">
            {records?.map((item, id) => (
              <Col md={4} key={id}>
                {item?.totalCorridors > 0 ? (
                  <ProjectCardTx data={item} />
                ) : item?.totalAssets > 0 ? (
                  <ProjectCardOil data={item} />
                ) : (
                  <ProjectCardSurvey data={item} />
                )}
              </Col>
            ))}
          </Row>
        ) : (
          <div className="text-center">No Projects available to display</div>
        )}
      </div>
    </React.Fragment>
  );
};

export default ClientDashboard;
