import React, { useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import { CardBody, CardHeader, Pagination } from "reactstrap";
import Loader from "../CommonComponent/Loader";
import { useNavigate } from "react-router-dom";
import SearchOption from "../Common/SearchOption";
import AdministrativeCircleTable from "../tables/AdministrativeCircleTable";
import CreateAdministrative from "./CreateAdministrative";
import DeleteModal from "../Common/DeleteModal";
import * as api from "../../api/global.api";

const AdministrativeCircle = () => {
  const [loading, setLoading] = useState<Boolean>(false);
  const navigate = useNavigate();
  const [search, setSearch] = useState<any>("");
  const [data, setData] = useState<any>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [mode, setMode] = useState<string>("Add Administrative Circle");
  const [editData, setEditData] = useState(null);
  const [page, setPage] = useState<any>({ count: 0, curr: 1 });
  const { count, curr } = page;
  const [deleteData, setDeleteData] = useState<any>(null);

  useEffect(() => {
    getAdministrativeCircles(curr);
  }, [curr, search]);

  const getAdministrativeCircles = (val: any) => {
    setLoading(true);
    let url = `page=${val}&limit=10`;
    if (search) {
      url += `&search=${search}`;
    }

    api
      .get(`clientcircle?${url}`)
      .then((res: any) => {
        if (res?.status?.toLowerCase() === "success") {
          setData(res?.response);
          setLoading(false);
        } else {
          setLoading(false);
          toast.error(res?.errormessage);
        }
      });
  };

  const handleSearch = (val: any) => {
    setSearch(val);
    if (val === "") {
      getAdministrativeCircles("page=0&limit=10");
    } else {
      getAdministrativeCircles(curr);
    }
  };

  const handleAddAdministrative = () => {
    setMode("Add Administrative Circle");
    setOpen(true);
  };

  const handleEdit = (val: any) => {
    console.log(val, "val");
    setEditData(val);
  };

  const handleDeleteData = () => {
    deleteCircle(deleteData?.id);
    setDeleteData(null);
  };

  const handleDelete = (val: any) => {
    setDeleteData(val);
  };

  const deleteCircle = (id: any) => {
    setLoading(true);
    api.deleteApi(`clientcircle/${id}`).then((res) => {
      if (res?.status?.toLowerCase() === "success") {
        getAdministrativeCircles(curr);
        toast.success("Administrative Circle deleted successfully");
        setLoading(false);
      } else {
        toast.error(res?.errorMessage);
      }
    });
  };

  const handlePageChange = (val: any) => {
    setPage({ ...page, curr: val });
  };

  return (
    <div className="px-3 mt-3 ptable">
      {loading && <Loader loader={loading} />}
      <ToastContainer />
      <Card className="card-border-none">
        <CardHeader className="header-card">
          <div className="d-flex justify-content-between align-items-center admin-card-head">
            <div>Administrative Circle</div>
            <Button
              color="success"
              onClick={() => {
                handleAddAdministrative();
              }}
              className="align-items-center"
            >
              <span className="me-2">+</span>
              <span className="admin-card-head">Add Administrative Circle</span>
            </Button>
          </div>
        </CardHeader>
        <CardBody>
          <SearchOption handleSearch={handleSearch} placeholder= "Search Administrative Circle" />
          <AdministrativeCircleTable
            data={data}
            handleEdit={handleEdit}
            setOpen={setOpen}
            handleDelete={handleDelete}
            setMode={setMode}
          />
          <Pagination
            itemsPerPage={12}
            count={count}
            handlePageChange={handlePageChange}
          />
        </CardBody>
      </Card>

      {open && (
        <CreateAdministrative
          mode={mode}
          open={open}
          setOpen={setOpen}
          editData={editData}
          handleEdit={handleEdit}
          getAdministrativeCircles={getAdministrativeCircles}
        />
      )}
      {deleteData !== null && (
        <DeleteModal
          show={deleteData !== null ? true : false}
          onDeleteClick={handleDeleteData}
          onCloseClick={() => setDeleteData(null)}
        />
      )}
    </div>
  );
};

export default AdministrativeCircle;
