import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import Loader from "../CommonComponent/Loader";
import {
  Button,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { alphanumerics } from "../commons/functions";
import Select from "react-select";
import * as api from "../../api/global.api";

const CreateAdministrative = (props: any) => {
  console.log("props", props);
  const [loading, setLoading] = useState<boolean>(false);
  const [clientOptions, setClientOptions] = useState<any>([]);
  const [selectedClient, setSelectedClient] = useState<any>(null);

  useEffect(() => {
    let optValue: any = [];
    clientOptions?.forEach((opt: any) => {
      props?.editData?.clients?.forEach((getOpt: any) => {
        if (opt?.value === getOpt?.id) {
          optValue.push(opt);
        }
      });
    });
    setSelectedClient(optValue || []);
    console.log("optValue", optValue);
  }, [clientOptions]);

  const initialValues = {
    circleName: props?.editData?.circleName || "",
    clients: props?.editData?.clients?.map((client: any) => client.id) || [],
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object({
      circleName: Yup.string()
        .matches(alphanumerics, "Please enter a valid name")
        .required("Required"),
    }),
    onSubmit: (values: any) => {
      const clientIds = selectedClient?.map((client: any) => client.value);
      const obj = {
        ...values,
        clients: clientIds,
      };
      props.mode === "Add Administrative Circle"
        ? addCircleApi(obj)
        : updateCircleApi(obj);
    },
  });

  useEffect(() => {
    if (props.mode === "Edit Administrative Circle" && props?.editData) {
      const transformedClients =
        props.editData.clients.map((client: any) => ({
          label: `${client.clientRef} - ${client.clientName}`,
          value: client.id,
        })) || [];

      const selectedClients = transformedClients.filter((client: any) =>
        props.editData.clients.some(
          (editClient: any) => editClient.id === client.value
        )
      );

      setSelectedClient(selectedClients);

      formik.setFieldValue(
        "clients",
        selectedClients.map((client: any) => client.value)
      );
    }
    console.log("props.editData.clients:", props.editData?.clients);
  }, [props.mode, props.editData]);

  console.log("clientOptions:", clientOptions);
  console.log("selectedClient:", selectedClient);

  const addCircleApi = (data: any) => {
    setLoading(true);
    api.post("clientcircle", data).then((res) => {
      if (res?.status?.toLowerCase() === "success") {
        setLoading(false);
        props?.setOpen(false);
        props?.getAdministrativeCircles(1);
        toast.success("Administrative Circle added successfully");
      } else {
        setLoading(false);
        props?.setOpen(false);
        toast.error(res?.errorrMessage);
      }
    });
  };

  const updateCircleApi = (data: any) => {
    console.log("data", data);
    setLoading(true);
    // api.put("clientcircle", data).then((res) => {
        api.put(`clientcircle/${props?.editData.id}`, data).then((res) => {
      if (res?.status?.toLowerCase() === "success") {
        setLoading(false);
        props?.getAdministrativeCircles(1);
        props?.setOpen(false);
        toast.success("Administrative Circle Updated successfully");
        props.handleEdit(null);
      } else {
        setLoading(false);
        props?.setOpen(false);
        toast.error(res?.errorrMessage);
        props.handleEdit(null);
      }
    });
  };

  useEffect(() => {
    getClients();
  }, []);

  const getClients = async () => {
    setLoading(true);
    try {
      const url = "clients/get?limit=1000";
      const res = await api.get(url);
      const clients = res?.response?.rows || [];

      const options = clients.map((client: any) => ({
        // label: client.clientName,
        label: `${client.clientRef} - ${client.clientName}`,
        value: client.id,
      }));

      setClientOptions(options);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleClient = (val: any) => {
    setSelectedClient(val);
    formik.setFieldValue(
      "clients",
      val?.map((option: any) => option.value)
    );
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <Loader loader={loading} />
      <Modal isOpen={props?.open}>
        <ModalHeader>
          <span className="mb-2 text-light">{props?.mode}</span>
          <span
            className="cross mb-2 text-light"
            onClick={() => {
              props?.setOpen(false);
              props.handleEdit(null);
            }}
          >
            X
          </span>
        </ModalHeader>
        <Form
          onSubmit={(e: any) => {
            e.preventDefault();
            formik.handleSubmit();
            return;
          }}
        >
          <ModalBody>
            <Row>
              <Col md={6}>
                <div className="mb-3">
                  <Label htmlFor="moduleName" className="form-label text-muted">
                    Administrative Circle
                  </Label>
                  <span className="mandatory"> *</span>
                  <Input
                    type="text"
                    className="form-control"
                    id="circleName"
                    name="circleName"
                    maxLength={15}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.circleName || ""}
                    invalid={
                      formik.touched.circleName && formik.errors.circleName
                        ? true
                        : false
                    }
                  />
                  {formik.touched.circleName && formik.errors.circleName ? (
                    <FormFeedback type="invalid">
                      {formik.errors.circleName}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>

              <Col md={6}>
                <div className="mb-3">
                  <Label className="form-label text-muted">
                    Assign Clients
                  </Label>
                  <Select
                    options={clientOptions}
                    isMulti={true}
                    value={selectedClient}
                    onChange={(val) => handleClient(val)}
                    placeholder="Select Clients"
                  />
                </div>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              style={{ width: "100px" }}
              onClick={() => {
                props.setOpen(false);
                props.handleEdit(null);
              }}
              color="success"
              outline
            >
              Cancel
            </Button>
            <Button type="submit" color="primary" style={{ width: "100px" }}>
              {props.editData === null ? "Save" : "Update"}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default CreateAdministrative;
