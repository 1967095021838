import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label,
  Modal,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

//import images
import avatar1 from "../../assets/images/users/avatar-1.jpg";
import { useNavigate } from "react-router-dom";
import { getDate } from "../../Pages/Components/Utils";
import { Valid } from "../../store/reducer";
import Loader from "./Loader";
import { ModalBody } from "react-bootstrap";
import Draggable from "react-draggable";
import * as api from "../../api/global.api";
import { ToastContainer, toast } from "react-toastify";
import Select from "react-select";

const ProfileDropdown = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => ({
    user: state?.user,
  }));

  //Dropdown Toggle
  const [isProfileDropdown, setIsProfileDropdown] = useState(false);
  const [isChangePassword, setIsChangePassword] = useState(false);
  const [isChangeRole, setIsChangeRole] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formState, setFormState] = useState({});
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [isError, setIsError] = useState(false);
  const [options, setOptions] = useState([]);
  const [moduleOption, setModuleOption] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null);
  const [selectedModule, setSelectedModule] = useState(null);

  const toggleProfileDropdown = () => {
    setIsProfileDropdown(!isProfileDropdown);
  };

  const handleLogout = () => {
    dispatch(Valid({}));
    navigate("/login");
  };

  const handleChangePassword = () => {
    setIsChangePassword(true);
  };

  const handleChangeRole = () => {
    const activeRoles = user.roles?.filter((e) => e.isSelected === true);
    const userRole = activeRoles?.filter(item => {
      if(item?.roleCode !== "SME" && item?.roleCode !== "PILOT"){
        return item
      }
    })
  
    const selectOptions = userRole?.map((e) => ({
      label: e.roleName,
      value: e.roleName,
    }));
    
    const selected = userRole.find((e) => e.roleName === user.selectedRole);
    let opt = selected?.privilliges.map((e) => ({
      label: e.subModule,
      value: e.subModule,
    }));
    setOptions(selectOptions);
    setModuleOption(opt);
    setSelectedRole({ label: user.selectedRole, value: user.selectedRole });
    setSelectedModule({
      label: user.selectedModule,
      value: user.selectedModule,
    });
    setIsChangeRole(true);
  };

  const handleInputChange = (value, field) => {
    setFormState({ ...formState, [field]: value });
    if (field === "confirmPassword" && isError) {
      checkPasswordMatch(value);
    }
  };

  const submitUpdatePassword = () => {
    setLoading(true);
    api
      .login(user?.email, formState.newPassword, formState.oldPassword)
      .then((data) => {
        if (data?.status?.toLowerCase() === "failed") {
          toast.error("Unable to update the password", "error");
          setLoading(false);
        } else {
          toast.success("Password updated successfully");
          setFormState({});
          setIsChangePassword(false);
          setTimeout(() => {
            handleLogout();
          }, 5000);
        }
      })
      .catch((err) => {
        toast.error("Unable to update the password", "error");
        setLoading(false);
      });
  };

  const disableConfirm = () => {
    if (
      !formState?.oldPassword ||
      !formState?.newPassword ||
      !formState?.confirmPassword ||
      isError
    ) {
      return true;
    } else {
      return false;
    }
  };

  const checkPasswordMatch = (value) => {
    if (formState?.newPassword !== value) {
      setIsError(true);
    } else {
      setIsError(false);
    }
  };

  const onConfirmRoleClick = () => {
    let data = {
      ...user,
      selectedRole: selectedRole?.value,
      selectedModule: selectedModule?.value,
    };
    dispatch(Valid(data));
    setIsChangeRole(false);
    selectedRole.value === "Sme" && navigate("/sme-dashboard");
    selectedRole.value === "Admin" && navigate("/admin-dashboard");
    selectedRole.value === "Pilot" && navigate("/pilot-dashboard");
    (selectedRole.value === "Client Admin" ||
      selectedRole.value === "Client User") &&
      navigate("/client-dashboard");
  };

  const handleRoleChange = (val) => {
    setSelectedRole(val);
    setSelectedModule(null);
    const selected = user.roles.find((e) => e.roleName === val.value);
    const opt = selected?.privilliges?.map((e) => ({
      label: e.subModule,
      value: e.subModule,
    }));
    setModuleOption(opt);
  };
  return (
    <React.Fragment>
      <ToastContainer />
      {loading && (
        <div className="loading">
          <Loader />
        </div>
      )}
      <Dropdown
        isOpen={isProfileDropdown}
        toggle={toggleProfileDropdown}
        className="ms-sm-3 header-item topbar-user"
      >
        <DropdownToggle
          tag="button"
          type="button"
          className="btn profile-width"
        >
          <span className="d-flex align-items-center">
            <img
              className="rounded-circle header-profile-user"
              src={user?.imageUrl ? user.imageUrl : avatar1}
              alt="Header Avatar"
            />
            <span className="text-start ms-xl-2 profile-details">
              <span className="d-xl-inline-block ms-1 fw-medium user-name-text text-dark">
                {/* {user?.loginName} */}
                {" " + user.firstName + " " + user.lastName}
              </span>
              {!props.client && (
                <span className="d-xl-block ms-1 fs-12 text-muted user-name-sub-text">
                  {user?.selectedRole}
                </span>
              )}
              <span className="d-xl-block ms-1 fs-12 text-muted user-name-sub-text">
                {getDate(user?.lastLoginDate)}
              </span>
            </span>
          </span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <h6 className="dropdown-header">
            Welcome {" " + user.firstName + " " + user.lastName}
          </h6>
          <DropdownItem onClick={() => navigate("/profile")}>
            <i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>
            <span className="align-middle">Profile</span>
          </DropdownItem>
          <div className="dropdown-divider"></div>
          <DropdownItem onClick={handleChangeRole}>
            <i className="mdi mdi-calendar-check-outline text-muted fs-16 align-middle me-1"></i>{" "}
            <span className="align-middle">Change Role</span>
          </DropdownItem>
          <div className="dropdown-divider"></div>
          <DropdownItem onClick={handleChangePassword}>
            <i className="mdi mdi-lifebuoy text-muted fs-16 align-middle me-1"></i>{" "}
            <span className="align-middle">Change Password</span>
          </DropdownItem>
          <div className="dropdown-divider"></div>
          <DropdownItem onClick={handleLogout}>
            <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>{" "}
            <span className="align-middle" data-key="t-logout">
              Logout
            </span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>

      {isChangePassword && (
        <Draggable>
          <Modal
            isOpen={isChangePassword}
            toggle={() => {
              setFormState({});
              setIsError(false);
              setIsChangePassword(false);
            }}
            size="sm"
          >
            <ModalHeader>
              <span className="text-light fs-18">Update Password</span>
              <i
                className="bx bx-x fs-2 text-light pointer"
                onClick={() => {
                  setFormState({});
                  setIsError(false);
                  setIsChangePassword(false);
                }}
              ></i>
            </ModalHeader>
            <ModalBody className="p-3">
              <div className="form-icon right mb-2">
                <Input
                  type={show1 ? "text" : "password"}
                  className="form-control form-control-icon"
                  id="iconrightInput"
                  placeholder="Enter Old Password"
                  value={formState?.oldPassword}
                  onChange={(e) =>
                    handleInputChange(e.target.value, "oldPassword")
                  }
                />
                {show1 ? (
                  <i className="bx bx-show" onClick={() => setShow1(false)}></i>
                ) : (
                  <i className="bx bx-hide" onClick={() => setShow1(true)}></i>
                )}
              </div>
              <div className="form-icon right mb-2">
                <Input
                  type={show2 ? "text" : "password"}
                  className="form-control form-control-icon"
                  id="iconrightInput"
                  placeholder="Enter New Password"
                  value={formState?.newPassword}
                  onChange={(e) =>
                    handleInputChange(e.target.value, "newPassword")
                  }
                />
                {show2 ? (
                  <i className="bx bx-show" onClick={() => setShow2(false)}></i>
                ) : (
                  <i className="bx bx-hide" onClick={() => setShow2(true)}></i>
                )}
              </div>
              <div className="form-icon right mb-2">
                <Input
                  type={show3 ? "text" : "password"}
                  className="form-control form-control-icon"
                  id="iconrightInput"
                  placeholder="Enter Confirm Password"
                  disabled={!formState?.newPassword}
                  value={formState?.confirmPassword}
                  onChange={(e) =>
                    handleInputChange(e.target.value, "confirmPassword")
                  }
                  onBlur={(e) => checkPasswordMatch(e.target.value)}
                />
                {show3 ? (
                  <i className="bx bx-show" onClick={() => setShow3(false)}></i>
                ) : (
                  <i className="bx bx-hide" onClick={() => setShow3(true)}></i>
                )}
              </div>
              {isError && <div className="error">Password not matched</div>}
            </ModalBody>
            <ModalFooter className="pt-3">
              <Button
                className="w-sm"
                color="success"
                outline
                onClick={() => {
                  setFormState({});
                  setIsError(false);
                  setIsChangePassword(false);
                }}
              >
                Cancel
              </Button>
              <Button
                className="w-sm"
                color="primary"
                onClick={submitUpdatePassword}
                disabled={disableConfirm()}
              >
                Update
              </Button>
            </ModalFooter>
          </Modal>
        </Draggable>
      )}

      {isChangeRole && (
        <Draggable>
          <Modal
            isOpen={isChangeRole}
            toggle={() => setIsChangeRole(false)}
            size="sm"
          >
            <ModalHeader>
              <span className="text-light">Change Role</span>
              <i
                className="bx bx-x fs-2 text-light pointer"
                onClick={() => setIsChangeRole(false)}
              ></i>
            </ModalHeader>
            <ModalBody className="mb-3">
              <Select
                options={options}
                value={selectedRole}
                onChange={(val) => handleRoleChange(val)}
                className="mb-3"
              ></Select>
              {selectedRole.value !== "Admin" && (
                <Select
                  options={moduleOption}
                  value={selectedModule}
                  onChange={(val) => setSelectedModule(val)}
                />
              )}
            </ModalBody>
            <ModalFooter className="pt-3">
              <Button
                className="w-sm"
                color="success"
                outline
                onClick={() => setIsChangeRole(false)}
              >
                Cancel
              </Button>
              <Button
                className="w-sm"
                color="primary"
                onClick={onConfirmRoleClick}
                // disabled={}
              >
                Confirm
              </Button>
            </ModalFooter>
          </Modal>
        </Draggable>
      )}
    </React.Fragment>
  );
};

export default ProfileDropdown;
