import React, { useState } from "react";
import { Input } from "reactstrap";

const SearchOption = (props) => {
  const [value, setValue] = useState("");
  const onChangeData = (value) => {
    props.handleSearch(value);
    setValue(value);
  };

  return (
    <React.Fragment>
      <form className="app-search d-md-block" style={{ marginLeft: "10px" }}>
        <div className="position-relative w-25" style={{ marginLeft: "10px" }}>
          <Input
            type="text"
            className="form-control"
            placeholder={props?.placeholder || "Search..."}
            id="search-options"
            value={value}
            onChange={(e) => {
              onChangeData(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
              }
            }}
          />
          <span className="mdi mdi-magnify search-widget-icon"></span>
          <span
            className="mdi mdi-close-circle search-widget-icon search-widget-icon-close d-none"
            id="search-close-options"
          ></span>
        </div>
      </form>
    </React.Fragment>
  );
};

export default SearchOption;
