import React, { useEffect, useRef, useState } from "react";
import TileLayer from "ol/layer/Tile";
import XYZ from "ol/source/XYZ.js";
import View from "ol/View";
import Map from "ol/Map.js";
import VectorSource from "ol/source/Vector";
import Draw from "ol/interaction/Draw.js";
import Overlay from "ol/Overlay.js";
import { unByKey } from "ol/Observable.js";
import { Vector as VectorLayer } from "ol/layer";
import { OSM } from "ol/source";
import { Circle as CircleStyle, Fill, Stroke, Style } from "ol/style.js";
import { LineString, Polygon } from "ol/geom.js";
import {
  getCorridorsBySourceId,
  getSourcesByProjectId,
  getTowersByCorridorId,
} from "../../api/dashboard.api";
import {
  Tooltip,
  createLayers,
  createLogo,
  formatArea,
  formatLength,
  makeRasterdata,
  sortBy,
} from "../Components/Utils";
import { fromLonLat, toLonLat } from "ol/proj";
import { GeoJSON } from "ol/format";
import { defaults } from "ol/control";
import fullScreeen from "../../assets/svg-new/full-screen-light.svg";
import globe from "../../assets/svg-new/satellite-light.svg";
import lineImg from "../../assets/svg-new/distance-light.svg";
import areaImg from "../../assets/svg-new/area-light.svg";
import pointerimg from "../../assets/svg-new/pointer-light.svg";
import deleteImg from "../../assets/svg-new/delete-light.svg";
import {
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Offcanvas,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import { OffcanvasBody, OffcanvasHeader } from "react-bootstrap";
import LeftPanal from "../ClientSurvey/LeftPanal";

const TxMap = (props) => {
  const mapElement = useRef();
  const mapRef = useRef(null);
  const [map, setMap] = useState(null);
  const [sources, setSources] = useState(null);
  const [vectorLayers, setVectorLayers] = useState([]);
  const [layers, setLayers] = useState(null);
  const [corridors, setCorridors] = useState([]);
  const [towers, setTowers] = useState([]);
  const [createMapMethod, setCreateMapMethod] = useState(false);
  const [smap, setSmap] = useState();
  const [layerState, setLayerState] = useState(null);
  const [fullMap, setFullMap] = useState(false);
  const [satellite, setSatellite] = useState(false);
  const [tool, setTool] = useState("none");
  const [toolState, setToolState] = useState();
  const [formState, setFormState] = useState();
  const [overlayData, setOVerlayData] = useState();
  const [navigateData, setNavigateData] = useState();
  const [type, setType] = useState(false);
  const [isCord, setIsCord] = useState(false);
  const [featData, setFeatData] = useState(null);
  const [isOff, setIsOff] = useState(false);
  const [legendsUrl, setLegendsUrl] = useState("");
  const [isLegends, setIsLegends] = useState(false);
  const [sourceState, setSourceState] = useState(null);

  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedSurvey, setSelectedSurvey] = useState(null);
  const [reports, setReports] = useState([]);

  mapRef.current = map;
  let info = document?.getElementById("info");

  const toggleLegends = () => {
    if (!isLegends) {
      setIsLegends(true);
    } else {
      setIsLegends(false);
    }
  };

  const createMap = async (proj) => {
    // // props.setLoading(true);
    // setCreateMapMethod(false);
    let source = await getSourcesByProjectId(proj?.id);
    props?.setSourceData(source?.findSources);
    props?.getCorridors(source?.corridors);
    let sourceData = source.findSources;
    sourceData = await sortBy(sourceData, "priority");
    let rData = await makeRasterdata(sourceData);
    setSources(rData[0]);

    let layerDetails = await sortBy(rData[1], "priority");
    setVectorLayers(layerDetails);
    setLayers(layerDetails);

    let idArr = sourceData?.map((ele) => ele.id);
    let data = await getCorridorsBySourceId(idArr.toString());
    setCorridors(data?.rows);
    let getTowers = await getTowersByCorridorId({
      id: data?.rows[0]?.id,
      page: 1,
      limit: 10,
    });
    setTowers(getTowers?.rows);
    setCreateMapMethod(true);
    // props.setLoading(false);
  };

  const basemap = (satellite) => {
    if (satellite) {
      const key = "dnSalYNT9GcjMQFNzx4G";
      const attributions =
        '<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> ' +
        '<a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>';
      const aerialLayer = new TileLayer({
        source: new XYZ({
          attributions: attributions,
          url:
            "https://api.maptiler.com/tiles/satellite/{z}/{x}/{y}.jpg?key=" +
            key,
          maxZoom: 20,
        }),
        visible: false,
      });
      setSmap(aerialLayer);
      return aerialLayer;
    } else {
      const baseLayer = new TileLayer({
        source: new OSM(),
      });
      return baseLayer;
    }
  };

  useEffect(() => {
    if (props.project) {
      createMap(props?.project);
    }
  }, [props?.project]);

  useEffect(() => {
    if (createMapMethod) {
      if (mapRef.current != null) {
        setMap(null);
        let element = document.getElementsByClassName("ol-viewport");
        while (element.length > 0) {
          element[0].parentNode?.removeChild(element[0]);
        }
      }

      const washingtonLonLat = [
        // corridors[0]?.endingPosition?.split(",")[0]?.trim(),
        // corridors[0]?.endingPosition?.split(",")[1]?.trim(),
        +props?.project?.longitude,
        +props?.project?.latitude,
      ];

      const washingtonWebMercator = fromLonLat(washingtonLonLat);

      let baseMap = new TileLayer({
        source: new OSM(),
      });

      let satelliteLayer = basemap(true);

      const initialMap = new Map({
        target: mapElement.current,
        layers: [baseMap, satelliteLayer],
        view: new View({
          center: washingtonWebMercator,
          zoom: +props?.project?.zoom || 2,
          minZoom: +props?.project?.minZoom || 20,
          maxZoom: +props?.project?.maxZoom || 2,
        }),
        controls: defaults({
          attributionOptions: {
            collapsible: true,
          },
        }),
      });
      initialMap.set("attribution", "");

      let LayersWithSources = createLayers(sources);
      setSourceState(LayersWithSources[1])
      
      let layersArray = LayersWithSources[0];
      layersArray?.map((layer) => {
        initialMap.addLayer(layer);
      });
      setLayerState(layersArray);
      setMap(initialMap);
    }
  }, [createMapMethod]);

  useEffect(() => {
    if (smap) {
      smap.setVisible(satellite);
    }
  }, [satellite]);

  useEffect(() => {
    let sketch;
    if (toolState) {
      map.removeInteraction(toolState);
    }
    if (mapRef.current != null && tool != "none") {
      const source = new VectorSource();
      const vector = new VectorLayer({
        source: source,
        style: {
          "fill-color": "rgba(255, 255, 255, 0.2)",
          "stroke-color": "#ffcc33",
          "stroke-width": 2,
          "circle-radius": 7,
          "circle-fill-color": "#ffcc33",
        },
      });
      map.addLayer(vector);
      addInteraction(map, source, sketch);
    }
  }, [tool]);

  const addInteraction = (map, source, sketch) => {
    let element = document.getElementsByClassName("ol-viewport");
    while (element.length > 1) {
      element[0].parentNode?.removeChild(element[0]);
    }
    let measureTooltipElement = document.createElement("div");
    let measureTooltip;
    const draw = new Draw({
      source: source,
      type: tool,
      style: new Style({
        fill: new Fill({
          color: "rgba(255, 255, 255, 0.2)",
        }),
        stroke: new Stroke({
          color: "rgba(0, 0, 0, 0.5)",
          lineDash: [10, 10],
          width: 2,
        }),
        image: new CircleStyle({
          radius: 5,
          stroke: new Stroke({
            color: "rgba(0, 0, 0, 0.7)",
          }),
          fill: new Fill({
            color: "rgba(255, 255, 255, 0.2)",
          }),
        }),
      }),
    });
    setToolState(draw);
    map.addInteraction(draw);
    measureTooltip = new Overlay({
      element: measureTooltipElement,
      offset: [0, -15],
      positioning: "bottom-center",
      stopEvent: false,
      insertFirst: false,
    });
    let data = map.addOverlay(measureTooltip);
    let listener;
    draw.on("drawstart", (evt) => {
      const sketch = evt.feature;
      let tooltipCoord = evt.coordinate;
      listener = sketch.getGeometry().on("change", function (evt) {
        const geom = evt.target;
        let output;
        measureTooltipElement.id = "measure";
        if (geom instanceof Polygon) {
          output = formatArea(geom);
          tooltipCoord = geom.getInteriorPoint().getCoordinates();
          measureTooltipElement.innerHTML = output;
          measureTooltip.setPosition(tooltipCoord);
        } else if (geom instanceof LineString) {
          output = formatLength(geom);
          tooltipCoord = geom.getLastCoordinate();
          measureTooltipElement.innerHTML = output;
          measureTooltip.setPosition(tooltipCoord);
        }
      });
    });
    draw.on("drawend", function (event) {
      const feature = event.feature;
      const geometry = feature.getGeometry();
      const geoJsonFormat = new GeoJSON();
      const geoJsonData = geoJsonFormat.writeGeometry(geometry);
      setFormState({ ...formState, geoJsonData: geoJsonData });
      measureTooltipElement.className = "ol-tooltip ol-tooltip-static";
      measureTooltip.setOffset([0, -7]);
      const overlayElement = document.getElementById("popup");
      const overlay = new Overlay({
        element: overlayElement,
        positioning: "bottom-center",
        offset: [0, -10],
      });
      map.addOverlay(overlay);
      const coordinate = [
        geometry.flatCoordinates[0],
        geometry.flatCoordinates[1],
      ];
      overlay.setPosition(coordinate);
      setOVerlayData(overlay);
      sketch = null;
      unByKey(listener);
    });
  };

  useEffect(() => {
    if (tool != "None") {
      const boxes = document.querySelectorAll(".elev-tooltip");
      boxes.forEach((box) => {
        box.remove();
      });
    }
  }, [tool]);

  useEffect(() => {
    if (mapRef.current != null) {
      const handleIdentifier = (evt) => {
        const visibleLayers = layersState?.filter(
          (layer) => layer.getVisible() && layer instanceof Image
        );
        visibleLayers.sort((a, b) => {
          return a.getZIndex() - b.getZIndex();
        });
        let sourceArray = [];
        visibleLayers.map((layer) => {
          sourceArray.push(layer.getSource());
        });
        if (sourceArray.length == 0) {
          visibleLayers.map((layer) => {
            sourceArray.push(layer.getSource());
          });
        }
        evt.stopPropagation();
        deleteElevation(".elev-tooltip");
        let layerParams = sourceArray[0]?.getParams().LAYERS || "";
        let view = map.getView();
        const viewResolution = map.getView().getResolution();
        let layerName = layerParams.split(":")[1];
        const url = sourceArray[0]?.getFeatureInfoUrl(
          evt.coordinate,
          viewResolution,
          view.getProjection(),
          { INFO_FORMAT: "application/json" }
        );
        let identifierData = {};
        if (url) {
          fetch(url, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods": "*",
            },
          })
            .then((response) => response.json())
            .then((data) => {
              if (data) {
                let corName = data?.features[0]?.properties?.Corridor_N;
                let corId = corridors?.find(
                  (ele) => ele.corridorName == corName
                );
                setNavigateData(corId);
              }
            });
        }
        // Assuming the layer name is the second part after the colon
      };
      map.on("singleclick", handleIdentifier);
      return () => {
        map.un("singleclick", handleIdentifier);
      };
    }
  });

  useEffect(() => {
    if (mapRef.current != null && layersState && sources && type == true) {
      const allLayers = map.getLayers();
      let visibleVectorlayers = [];
      const testlayers = allLayers.getArray().filter((layer) => {
        if (layer instanceof VectorLayer && layer.getVisible()) {
          // If it is, add it to the list of visible vector layers
          visibleVectorlayers.push(layer);
        }
      });
      visibleVectorlayers.map((layers) => layers.setVisible(false));
      deleteElevation(".ol-tooltip");
      deleteElevation("#measure");
      map.removeInteraction(toolState);
      setTool("none");
      setType(false);
    }
  }, [type]);

  const deleteElevation = (data) => {
    const boxes = document.querySelectorAll(data);
    if (boxes.length > 0) {
      boxes.forEach((box) => {
        box.remove();
      });
    }
  };

  useEffect(() => {
    if (isCord) {
      const handleView = (evt) => {
        let cord = evt.coordinate;
        let mapcord = toLonLat(cord);
        let x = mapcord[0].toFixed(4);
        let y = mapcord[1].toFixed(4);
        info.innerHTML = x + ", " + y;
      };

      const handleView2 = (evt) => {
        let coordinate = evt?.coordinate;
        let viewResolution = map?.getView()?.getResolution();
        let view = map.getView();
        let latestLayer = layersState[0].values_?.source;
        let url = latestLayer?.getFeatureInfoUrl(
          coordinate,
          viewResolution,
          view.getProjection(),
          { INFO_FORMAT: "application/json" }
        );

        if (url) {
          fetch(url, {
            method: "POST",
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods": "*",
            },
          })
            .then((res) => res.json())
            .then((data) => {
              console.log(data);
              if (data.features && data.features[0]) {
                setFeatData(data?.features[0]);
                setIsOff(true);
              } else {
                setFeatData(null);
                setIsOff(false);
              }
            })
            .catch((err) => console.log(err));
        }
      };
      map.on("click", handleView2);
      map.on("pointermove", handleView);
      return () => {
        map.un("click", handleView);
        map.un("click", handleView2);
      };
    }
  }, [isCord]);

  const handleLegendClick = () => {
    const baseUrl = process.env.REACT_APP_GEOSERVER_URL + "wms";
    const params = {
      REQUEST: "GetLegendGraphic",
      WIDTH: 20,
      HEIGHT: 20,
      LAYER: props?.sourceData?.sourceName,
      format: "image/png",
    };

    const constructUrl = (baseUrl, params) => {
      setLegendsUrl("");
      const queryParams = new URLSearchParams(params).toString();
      return `${baseUrl}?${queryParams}`;
    };

    const legendUrls = constructUrl(baseUrl, params);
    setLegendsUrl(legendUrls);
  };

  useEffect(() => {
    if (props?.sourceData) {
      handleLegendClick();
    }
  }, [props?.sourceData]);

  const changeVisibility = (e, idx) => {
    let data = [...vectorLayers];
    let newdata = vectorLayers[idx];
    newdata.visibility = +e.target.checked;
    data[idx] = newdata;
    setVectorLayers(data);
  };

  useEffect(() => {
    if (vectorLayers?.length > 0) {
      layerState?.map((item, idx) => {
        if (vectorLayers[idx]?.visibility === 1) {
          item.setVisible(true);
        } else {
          item.setVisible(false);
        }
      });
    }
  }, [vectorLayers]);

  return (
    <React.Fragment>
      <div
        ref={mapElement}
        // className={`${fullMap ? "tx-fullmap" : "tx-halfmap"}`}A
        className="tx-fullmap"
        id="map-container"
      ></div>
      <div className="show-layers-on-map">
        <UncontrolledDropdown className="" direction="up">
          <DropdownToggle color="primary">
            <i class="ri-stack-line fs-2"></i>
          </DropdownToggle>
          <DropdownMenu>
            {vectorLayers?.map((e, idx) => (
              <div className="">
                <div className="d-flex justify-content-between align-items-center p-2">
                  <div>{e?.displayname}</div>
                  <div>
                    <input
                      type="checkbox"
                      name=""
                      id=""
                      checked={e?.visibility}
                      onClick={(e) => changeVisibility(e, idx)}
                    />
                  </div>
                </div>
              </div>
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>

      <div
        id="info"
        className="coordinate-on-map"
        style={{ display: isCord ? "" : "none" }}
      ></div>
      <div id="info2" style={{ display: isCord ? "" : "none" }}></div>
      <div className={`${fullMap ? "top-tx-panal1" : "top-tx-panal"}`}>
        <div className="d-flex align-items-center">
          <div className="tx-logo-client me-2">
            {props?.project?.client?.clientLogo ? (
              <img src={props?.project?.client?.clientLogo} width="100%" />
            ) : (
              <span>{createLogo(props?.project?.client.clientName)}</span>
            )}
          </div>
          <div className="tx-projectName text-dark">
            {props?.project?.projectName}
          </div>
        </div>
      </div>

      <div className={`${fullMap ? "tx-controls4" : "tx-controls2"}`}>
        <img
          src={globe}
          width="70%"
          className="pointer"
          id="satellite"
          onClick={() => setSatellite(!satellite)}
        />
        <i
          class="bx bx-current-location fs-3 text-light pointer"
          onClick={() => setIsCord(!isCord)}
        ></i>
        <img
          src={lineImg}
          width="70%"
          className="mb-2 pointer"
          id="mdistance"
          onClick={() => setTool("LineString")}
        />
        <img
          src={areaImg}
          width="70%"
          className="mb-2 pointer"
          id="marea"
          onClick={() => setTool("Polygon")}
        />
        <img
          src={pointerimg}
          width="70%"
          className="pointer"
          id="defaultpointer"
          onClick={() => setTool("none")}
        />
        <img
          src={deleteImg}
          width="70%"
          className="pointer"
          id="deleteimg"
          style={{ marginBottom: "10px" }}
          onClick={() => setType(true)}
        />
        {Tooltip("satellite", "Satellite", "right")}
        {Tooltip("mdistance", "Measure Distance", "right")}
        {Tooltip("marea", "Measure Area", "right")}
        {Tooltip("defaultpointer", "Default Pointer", "right")}
        {Tooltip("deleteimg", "Delete", "right")}
      </div>
      <div className="legends2">
        <UncontrolledDropdown
          isOpen={isLegends}
          toggle={toggleLegends}
          className="me-2"
          direction="up"
        >
          <DropdownToggle color="primary">
            <i className="bx bx-left-indent fs-2"></i>
          </DropdownToggle>
          {legendsUrl === "" && (
            <DropdownMenu
              style={{
                maxHeight: "600px",
                overflowY: "auto",
                transform: "translate(90px, -49.5px)",
              }}
            >
              <DropdownItem>
                <img src={legendsUrl} />
              </DropdownItem>
            </DropdownMenu>
          )}
        </UncontrolledDropdown>
      </div>
      <Offcanvas isOpen={isOff} toggle={() => setIsOff(false)} direction="end">
        <OffcanvasHeader toggle={() => setIsOff(false)} className="bg-success">
          <div className="w-100 d-flex align-items-center justify-content-between text-light">
            <span>Tower Details</span>
            <span>
              <i
                className="bx bx-x fs-4 pointer"
                onClick={() => setIsOff(false)}
              ></i>
            </span>
          </div>
        </OffcanvasHeader>
        <OffcanvasBody>
          <Row>
            <Col xs={6}>Line Name</Col>
            <Col xs={6}>{featData?.properties?.Line_Name}</Col>

            <Col xs={6}>Tower Number</Col>
            <Col xs={6}>{featData?.properties?.Tower_Numb}</Col>

            <Col xs={6}>Tower Type</Col>
            <Col xs={6}>{featData?.properties?.Tower_Type}</Col>

            <Col xs={6}>Tower Category</Col>
            <Col xs={6}>{featData?.properties?.Tower_Cate}</Col>

            <Col xs={6}>Structure</Col>
            <Col xs={6}>{featData?.properties?.Structure}</Col>

            <Col xs={6}>Circuit</Col>
            <Col xs={6}>{featData?.properties?.Circuit}</Col>

            <Col xs={6}>Voltage</Col>
            <Col xs={6}>{featData?.properties?.Voltage}</Col>

            <Col xs={6}>Latitude</Col>
            <Col xs={6}>{featData?.properties?.Latitude}</Col>

            <Col xs={6}>Longitude</Col>
            <Col xs={6}>{featData?.properties?.Longitude}</Col>
          </Row>
        </OffcanvasBody>
      </Offcanvas>

      {selectedProject !== null && selectedSurvey !== null && (
        <div className="survey-map">
          <LeftPanal
            layers={layers}
            setLayers={setLayers}
            project={selectedProject}
            // setLoading={setLoading}
            changeVisibilty={changeVisibilty}
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default TxMap;
