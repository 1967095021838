import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import {
  Capitalize,
  coodinateRegex,
  floatLength,
} from "../../commons/functions";
import * as Yup from "yup";
import Select from "react-select";
import { useLocation, useParams } from "react-router-dom";
import Loader from "../../CommonComponent/Loader";
import { ToastContainer, toast } from "react-toastify";
import * as api from "../../../api/global.api";
import { useAppSelector } from "../../../store/hooks";

const CreateCorridor = (props: any) => {
  const id = useParams().id;
  const [loading, setLoading] = useState<any>(false);
  const user = useAppSelector((state) => state.user);
  const loc = useLocation()?.state;

  const [countryOption, setCountryOption] = useState<any>([]);
  const [stateOption, setStateOption] = useState<any>([]);
  const [smeOption, setSmeOption] = useState<any>([]);

  const [selectedCountry, setSelectedCountry] = useState<any>(null);
  const [selectedState, setSelectedState] = useState<any>(null);
  const [selectedSme, setSelectedSme] = useState<any>(null);
  const [selectedClientUser, setSelectedClientUser] = useState<any>(null);
  const [clientUsers, setClientUsers] = useState<any>([]);

  const handleCountry = (val: any) => {
    setSelectedCountry(val);
    formik.setFieldValue("country", val.label + "_" + val.code);
    getState(val.code);
  };
  const handleState = (val: any) => {
    setSelectedState(val);
    formik.setFieldValue("state", val.label);
  };

  const handleSme = (val: any) => {
    setSelectedSme(val);
    formik.setFieldValue(
      "sme",
      val.map((option: any) => option.label)
    );
  };

  const handleClientUser = (val: any) => {
    setSelectedClientUser(val);
    formik.setFieldValue(
      "user",
      val.map((option: any) => String(option.value))
    );
  };

  const getCountries = () => {
    setLoading(true);
    api.get("getCountries").then((res) => {
      let data = res?.map((e: any) => {
        return { label: e.name, value: e.name, code: e.isoCode };
      });
      setCountryOption(data);
      setLoading(false);
    });
  };

  const getState = (val: any) => {
    setLoading(true);
    api.get(`getStateByCountry?countryCode=${val}`).then((res) => {
      let data = res?.map((e: any) => {
        return { label: e.name, value: e.name, code: e.isoCode };
      });
      setStateOption(data);
      sessionStorage.setItem("states", JSON.stringify(data));
      setLoading(false);
    });
  };

  const getSme = () => {
    setLoading(true);
    api.get("user/get/Sme").then((res) => {
      if (res?.status?.toLowerCase() === "success") {
        let data = res?.response?.map((e: any) => {
          return {
            label: e.User.firstName + " " + e.User.lastName,
            value: e.User.firstName + " " + e.User.lastName,
          };
        });
        setSmeOption(data);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(res?.errormessage);
      }
    });
  };

  const getAdmins = () => {
    api.get(`user/get?projectId=${loc?.projectId}`).then((res) => {
      if (res.status?.toLowerCase() === "success") {
        const users = res.response.rows.map((e: any) => ({
          label: `${e.firstName} ${e.lastName}`,
          value: e.id,
        }));
        setClientUsers(users);
        setLoading(false);
      }
    });
  };

  const formik = useFormik({
    initialValues: {
      corridorName: "",
      lineName: "",
      startingPosition: "",
      endingPosition: "",
      corridorLength: "",
      noOfTowers: "",
      country: null,
      state: null,
      address: "",
      // sme: null,
      sme: [],
      user: [],
    },
    validationSchema: Yup.object({
      corridorName: Yup.string().required("Required"),
      lineName: Yup.string().required("Required"),
      startingPosition: Yup.string()
        .matches(coodinateRegex, "Please enter valid coordinate")
        .required("Required"),
      endingPosition: Yup.string()
        .matches(coodinateRegex, "Please enter valid coordinate")
        .required("Required"),
      corridorLength: Yup.string()
        .matches(floatLength, "Please enter valid number")
        .required("Required"),
      noOfTowers: Yup.string()
        .matches(/^[1-9][0-9]*$/, "Please enter valid number")
        .required("Required"),
      country: Yup.string().required("Required"),
      state: Yup.string().required("Required"),
      address: Yup.string().required("Required"),
      // sme: Yup.string().required("Required"),
      sme: Yup.array()
        .of(Yup.string())
        .min(1, "Please select at least one SME")
        .required("Required"),
      user: Yup.array()
        .min(1, "Please select at least one client user")
        .required("Required"),
    }),
    onSubmit: (values) => {

      let obj = {};
      if (props.mode === "Add Corridor") {
        obj = {
          ...values,
          sme: values?.sme?.join(", "),
          // user: values?.user,
          user: values?.user.map((id) => String(id)),
          createdBy: user?.loginName,
          sources: { connect: { id: Number(id) } },
        };
      } else {
        obj = {
          ...values,
          sme: values?.sme?.join(", "),
          // user: values?.user,
          user: values?.user.map((id) => String(id)),
          createdBy: user?.loginName,
          sourcesId: Number(id),
          id: Number(props?.editData?.id),
        };
      }

      if (props?.mode === "Add Corridor") {
        addCorridorApi(obj);
      } else {
        if (+values?.noOfTowers < +props?.editData?.noOfTowers) {
          toast.warn(
            "The number of towers cannot be less than the current value."
          );
        } else {
          updateCorridorApi(obj);
        }
      }
    },
  });

  const addCorridorApi = (data: any) => {
    setLoading(true);
    api.post("addCorridor", data).then((res) => {
      if (res?.status?.toLowerCase() === "success") {
        setLoading(false);
        props?.getCorridor(1);
        props?.setOpen(false);
        toast.success("Corridor added successfully");
      } else {
        setLoading(false);
        toast.error(res?.errormessage);
      }
    });
  };

  const updateCorridorApi = (data: any) => {
    setLoading(true);
    api.put("updateCorridor", data).then((res) => {
      if (res?.status?.toLowerCase() === "success") {
        setLoading(false);
        props?.getCorridor(1);
        props?.setOpen(false);
        props.handleEdit(null);

        toast.success("Corridor updated successfully");
      } else {
        setLoading(false);
        props.handleEdit(null);
        toast.error(res?.errormessage);
      }
    });
  };

  useEffect(() => {
    getCountries();
    getSme();
    getAdmins();
    if (props?.mode === "Edit Corridor") {
      console.log(props?.editData, "props.editData");
      let iso = (props?.editData?.country?.split("_"))[1];
      let country = (props?.editData?.country?.split("_"))[0];
      formik.setFieldValue("corridorName", props?.editData?.corridorName);
      formik.setFieldValue("lineName", props?.editData?.lineName);
      formik.setFieldValue(
        "startingPosition",
        props?.editData?.startingPosition
      );
      formik.setFieldValue("endingPosition", props?.editData?.endingPosition);
      formik.setFieldValue("corridorLength", props?.editData?.corridorLength);
      formik.setFieldValue("noOfTowers", props?.editData?.noOfTowers);
      formik.setFieldValue("address", props?.editData?.address);
      formik.setFieldValue("country", props?.editData?.country);
      formik.setFieldValue("state", props?.editData?.state);
      formik.setFieldValue("sme", props?.editData?.sme.split(", "));
      formik.setFieldValue(
        "user",
        props?.editData?.user?.map((e: any) => ({
          label: e?.firstName + " " + e?.lastName,
          value: e?.id,
        }))
      );
      setSelectedClientUser(
        props?.editData?.user?.map((e: any) => ({
          label: e?.firstName + " " + e?.lastName,
          value: e?.id,
        }))
      );

      setSelectedCountry({
        label: country,
        value: country,
      });
      setSelectedState({
        label: props?.editData?.state,
        value: props?.editData?.state,
      });

      let data = props?.editData?.sme?.split(", ");
      let data1 = data?.map((e: any) => ({
        label: e,
        value: e,
      }));
      setSelectedSme(data1);
      getState(iso);
    }
  }, []);

  return (
    <>
      <Loader loader={loading} />
      <ToastContainer />
      <Modal isOpen={props?.open}>
        <ModalHeader>
          <span className="mb-2 text-light">{props?.mode}</span>
          <span
            className="cross mb-2 text-light"
            onClick={() => {
              props?.setOpen(false);
              props.handleEdit(null);
            }}
          >
            X
          </span>
        </ModalHeader>
        <Form
          onSubmit={(e: any) => {
            e.preventDefault();
            formik.handleSubmit();
            return;
          }}
        >
          <ModalBody>
            <Row>
              <Col md={6}>
                <div className="mb-3">
                  <Label
                    htmlFor="corridorName"
                    className="form-label text-muted"
                  >
                    Corridor
                  </Label>
                  <span className="mandatory"> *</span>
                  <Input
                    type="text"
                    className="form-control"
                    id="corridorName"
                    name="corridorName"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.corridorName}
                    invalid={
                      formik.touched.corridorName && formik.errors.corridorName
                        ? true
                        : false
                    }
                  />
                  {formik.touched.corridorName && formik.errors.corridorName ? (
                    <FormFeedback type="invalid">
                      {formik.errors.corridorName}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <Label htmlFor="lineName" className="form-label text-muted">
                    Line Name
                  </Label>
                  <span className="mandatory"> *</span>
                  <Input
                    type="text"
                    className="form-control"
                    id="lineName"
                    name="lineName"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.lineName}
                    invalid={
                      formik.touched.lineName && formik.errors.lineName
                        ? true
                        : false
                    }
                  />
                  {formik.touched.lineName && formik.errors.lineName ? (
                    <FormFeedback type="invalid">
                      {formik.errors.lineName}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <Label
                    htmlFor="startingPosition"
                    className="form-label text-muted"
                  >
                    Starting Position
                  </Label>
                  <span className="mandatory"> *</span>
                  <Input
                    type="text"
                    className="form-control"
                    id="startingPosition"
                    name="startingPosition"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={Capitalize(formik.values.startingPosition) || ""}
                    invalid={
                      formik.touched.startingPosition &&
                      formik.errors.startingPosition
                        ? true
                        : false
                    }
                  />
                  {formik.touched.startingPosition &&
                  formik.errors.startingPosition ? (
                    <FormFeedback type="invalid">
                      {formik.errors.startingPosition}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <Label
                    htmlFor="endingPosition"
                    className="form-label text-muted"
                  >
                    Ending Position
                  </Label>
                  <span className="mandatory"> *</span>
                  <Input
                    type="text"
                    className="form-control"
                    id="endingPosition"
                    name="endingPosition"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={Capitalize(formik.values.endingPosition) || ""}
                    invalid={
                      formik.touched.endingPosition &&
                      formik.errors.endingPosition
                        ? true
                        : false
                    }
                  />
                  {formik.touched.endingPosition &&
                  formik.errors.endingPosition ? (
                    <FormFeedback type="invalid">
                      {formik.errors.endingPosition}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <Label
                    htmlFor="corridorLength"
                    className="form-label text-muted"
                  >
                    Corridor Length(Km)
                  </Label>
                  <span className="mandatory"> *</span>
                  <Input
                    type="text"
                    className="form-control"
                    id="corridorLength"
                    name="corridorLength"
                    value={formik.values.corridorLength || ""}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={
                      formik.touched.corridorLength &&
                      formik.errors.corridorLength
                        ? true
                        : false
                    }
                  />
                  {formik.touched.corridorLength &&
                  formik.errors.corridorLength ? (
                    <FormFeedback type="invalid">
                      {formik.errors.corridorLength}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <Label htmlFor="noOfTowers" className="form-label text-muted">
                    Number of Towers
                  </Label>
                  <span className="mandatory"> *</span>
                  <Input
                    type="text"
                    className="form-control"
                    id="noOfTowers"
                    name="noOfTowers"
                    value={formik.values.noOfTowers || ""}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={
                      formik.touched.noOfTowers && formik.errors.noOfTowers
                        ? true
                        : false
                    }
                    // disabled={props?.mode === "Edit Corridor"}
                  />
                  {formik.touched.noOfTowers && formik.errors.noOfTowers ? (
                    <FormFeedback type="invalid">
                      {formik.errors.noOfTowers}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <Label className="form-label text-muted">Country</Label>
                  <span className="mandatory"> *</span>
                  <Select
                    options={countryOption}
                    value={selectedCountry}
                    onChange={(val) => handleCountry(val)}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <Label className="form-label text-muted">State</Label>
                  <span className="mandatory"> *</span>
                  <Select
                    options={stateOption}
                    value={selectedState}
                    onChange={(val) => handleState(val)}
                  />
                </div>
              </Col>

              <Col md={6}>
                <div className="mb-3">
                  <Label className="form-label text-muted">Assign to SME</Label>
                  <span className="mandatory"> *</span>
                  <Select
                    options={smeOption}
                    isMulti={true}
                    value={selectedSme}
                    onChange={(val) => handleSme(val)}
                  />
                </div>
              </Col>

              <Col md={6}>
                <div className="mb-3">
                  <Label className="form-label text-muted">
                    Assign Client Users
                  </Label>
                  <span className="mandatory"> *</span>
                  <Select
                    options={clientUsers}
                    isMulti={true}
                    value={selectedClientUser}
                    onChange={(val) => handleClientUser(val)}
                  />
                </div>
              </Col>

              <Col md={6}>
                <div className="mb-3">
                  <Label htmlFor="priority" className="form-label text-muted">
                    City
                  </Label>
                  <span className="mandatory"> *</span>
                  <Input
                    type="textarea"
                    className="form-control"
                    id="address"
                    name="address"
                    maxLength={15}
                    value={Capitalize(formik.values.address)}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={
                      formik.touched.address && formik.errors.address
                        ? true
                        : false
                    }
                  ></Input>
                  {formik.touched.address && formik.errors.address ? (
                    <FormFeedback type="invalid">
                      {formik.errors.address}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              style={{ width: "100px" }}
              onClick={() => {
                props.setOpen(false);
                props.handleEdit(null);
              }}
              color="success"
              outline
            >
              Cancel
            </Button>
            <Button type="submit" color="primary" style={{ width: "100px" }}>
              {props.editData === null ? "Save" : "Update"}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};

export default CreateCorridor;
