import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import toimg from "../../../assets/images/uploadImg.svg";
import * as api from "../../../api/global.api";
import {
  GOOD,
  HIGH_RISK,
  MAJOR,
  MEDIUM_RISK,
  MINOR,
  severityStatus,
} from "../../commons/functions";
import Loader from "../../CommonComponent/Loader";
import { toast, ToastContainer } from "react-toastify";
import { checkFeatures } from "../../CommonComponent/CommonFunctions";
import { useAppSelector } from "../../../store/hooks";

const DashboardCard = () => {
  const navigate = useNavigate();
  const siteId = useParams().id;
  const [assetList, setAssetList] = useState([]);
  const [loader, setLoader] = useState(false);
  const userPriv = useAppSelector((state) => state.user)?.selectedFeatures;

  useEffect(() => {
    getAssets();
  }, [siteId]);

  const getAssets = () => {
    setLoader(true);
    api
      .post(`getAssets?projectId=${+siteId}&isActive=true`, {})
      .then((res) => {
        if (res?.response?.assetList) {
          setAssetList(res?.response?.assetList);
        }
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        toast.error(err);
      });
  };

  const navigateToAssetDetail = (asset) => {
    navigate(`/client-projects/${siteId}/${asset.id}`, {
      state: { data: asset },
    });
  };

  const getAssetPdfUrl = (asset) => {
    setLoader(true);
    let path = `pdfDownload/${asset?.id}`;
    api
      .get(path)
      .then((res) => {
        if (res?.url) {
          downloadPdf(res?.url, asset?.assetName);
        } else if (res?.status === "failed") {
          toast.error(res?.message);
        }
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        toast.error(err);
      });
  };

  const downloadPdf = (url, filename) => {
    const link = document.createElement("a");
    link.href = url;
    link.download = `${filename}.pdf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <React.Fragment>
      {/* <Loader loader={loader} /> */}
      <ToastContainer />
      <Row className="mt-4">
        {assetList.map((asset, key) => (
          <React.Fragment key={key}>
            <Col xl={3} lg={4}>
              <Card
                style={{
                  height: "200px",
                }}
              >
                <img src={asset?.assetLogo} />
                <CardBody className="p-0">
                  <div
                    className={`d-flex justify-content-center align-items-center position-absolute top-0 end-0 client-asset-card-ribbon ${
                      asset.severityStatus === GOOD
                        ? "lowRisk"
                        : asset.severityStatus === MEDIUM_RISK
                        ? "mediumRisk"
                        : asset.severityStatus === HIGH_RISK
                        ? "highRisk"
                        : asset.severityStatus === MAJOR
                        ? "major"
                        : asset.severityStatus === MINOR
                        ? "minor"
                        : ""
                    } `}
                  >
                    {severityStatus[asset.severityStatus]}
                  </div>
                  <div className="position-absolute bottom-0 w-100 bg-dark bg-opacity-75 h110px">
                    <div className=" p-3 text-light">{asset.assetName}</div>
                    <Row className="h-50 m-0">
                      {checkFeatures(
                        "View observations for assets and charts - Oil and Gas",
                        userPriv
                      ) && (
                        <Col lg={6} className="d-flex align-items-center">
                          <div
                            onClick={() => navigateToAssetDetail(asset)}
                            className="pointer primary"
                          >
                            View More
                          </div>
                        </Col>
                      )}
                      {checkFeatures(
                        "Download Report - Oil and Gas",
                        userPriv
                      ) && (
                        <Col
                          lg={6}
                          className="p-2 d-flex align-items-center justify-content-end"
                        >
                          <span
                            className="btn btn-light d-flex align-items-center"
                            onClick={() => getAssetPdfUrl(asset)}
                          >
                            <img src={toimg} height="17" alt="pdf" />
                            <span className="ps-2 primaryColor">Download</span>
                          </span>
                        </Col>
                      )}
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </React.Fragment>
        ))}
      </Row>
    </React.Fragment>
  );
};

export default DashboardCard;
