import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../components/commons/functions";
import { useEffect, useState } from "react";
import * as api from "../../api/global.api";
import { toast } from "react-toastify";
import SearchOption from "../../components/Common/SearchOption";
import { Row } from "react-bootstrap";
import homeicon from "../../assets/svg-new/home-icon.svg";
import { useAppSelector } from "../../store/hooks";

const CorridorTable = (props) => {
  console.log(props, "props", props?.location?.id);
  const user = useAppSelector((state) => state.user);
  console.log("user",user)
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [corridorData, setCorridorData] = useState([]);
  const [sourceData, setSourceData] = useState([]);
  console.log(sourceData);

  let id = props?.location?.id;

  const getCorridors = () => {
    setLoading(true);

    let url = `getCorridor?projectId=${id}&user=${user?.id}`;
    if (searchTerm) {
      url += `&search=${searchTerm}`;
    }
    api.get(url).then((res) => {
      if (res?.status?.toLowerCase() === "success") {
        setData(res?.response?.rows);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(res?.errormessage);
      }
    });
  };

  useEffect(() => {
    getCorridors();
  }, [searchTerm]);

  const navigate = useNavigate();

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">Corridor Name</span>,
      selector: (row) => (
        <div className="selectable-column" onClick={() => handleClick(row)}>
          {row.corridorName}
        </div>
      ),
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Line Name</span>,
      sortable: true,
      selector: (row) => row.lineName,
    },
    {
      name: <span className="font-weight-bold fs-13">Corridor Length</span>,
      selector: (row) => row.corridorLength,
      sortable: true,
      grow: 0.5,
    },
    {
      name: <span className="font-weight-bold fs-13">Starting Position</span>,
      selector: (row) => {
        let lat = row?.startingPosition?.split(",")[0];
        let long = row?.startingPosition?.split(",")[1];
        return (
          <span>
            {lat} <span className="fs-18">, </span> {long}
          </span>
        );
      },
      grow: 2,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Ending position</span>,
      selector: (row) => {
        let lat = row?.endingPosition?.split(",")[0];
        let long = row?.endingPosition?.split(",")[1];
        return (
          <span>
            {lat} <span className="fs-18">, </span> {long}
          </span>
        );
      },
      grow: 2,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Number of Towers</span>,
      selector: (row) => row.noOfTowers,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">City/ Division</span>,
      selector: (row) => row.address,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Status</span>,
      selector: (row) => row.corridorStatus,
      sortable: true,
    },
  ];

  const handleClick = (row) => {
    const corridor = data.find((e) => e.corridorName === row?.corridorName);
    if (corridor) {
      navigate("/client-tx-towers", {
        state: {
          project: props.location,
          corridor: corridor,
          sources: sourceData,
        },
      });
    }
  };

  return (
    <>
      <div>
        <SearchOption
          placeholder="Search Corridor Name & City/ Division"
          handleSearch={(val) => setSearchTerm(val)}
        />
        <DataTable columns={columns} data={data} />
      </div>
    </>
  );
};

export default CorridorTable;
