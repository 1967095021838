export const menuData = [
  {
    lable: "Dashboard",
    link: "/admin-dashboard",
    icon: "ri-home-2-fill",
  },
  {
    lable: "User Management",
    link: "/users",
    icon: "fa-users fa-solid py-2",
  },
  {
    lable: "Client Management",
    link: "/clients",
    icon: "fa-solid fa-user-tie py-2",
  },
  {
    lable: "Module Management",
    link: "/modules",
    icon: "ri-layout-grid-line",
  },
  {
    lable: "Administrative Circle Config",
    link: "/administrative",
    icon: "ri-map-pin-5-fill py-2",
  },
];
