import React from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../commons/functions";

const AdministrativeCircleTable = ({
  data,
  handleEdit,
  handleDelete,
  setOpen,
  setMode,
}) => {
  const navigate = useNavigate();

  const columns = [
    {
      name: (
        <span className="font-weight-bold column-title">
          Administrative Circle
        </span>
      ),
      selector: (row) => row.circleName,
      sortable: true,
    },
    {
      name: (
        <span className="font-weight-bold column-title">No of Clients</span>
      ),
      selector: (row) => row.noOfClients,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold column-title">Created Date</span>,
      selector: (row) => {
        return formatDate(row.createdAt);
      },
      sortable: true,
    },
    {
      name: <span className="font-weight-bold column-title">Updated Date</span>,
      selector: (row) => {
        return formatDate(row.updatedAt);
      },
      sortable: true,
    },
    {
      name: <span className="font-weight-bold column-title">Actions</span>,
      sortable: true,
      grow: 1.5,

      cell: (row) => {
        return (
          <span>
            <i
              className="bx bx-edit fs-4 me-2 pointer"
              style={{ color: "green" }}
              data-pr-tooltip="Edit"
              data-pr-position="top"
              onClick={() => {
                setMode("Edit Administartive Circle");
                handleEdit(row);
                setOpen(true);
              }}
            ></i>
            <i
              className="ri-delete-bin-6-line fs-4 me-3 pointer"
              style={{ color: "red" }}
              onClick={() => handleDelete(row)}
            ></i>
          </span>
        );
      },
    },
  ];

  return (
    <>
      <DataTable columns={columns} data={data} striped highlightOnHover />
    </>
  );
};

export default AdministrativeCircleTable;
