import moment from "moment";

export const Capitalize = (str) => {
  return str && str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const phoneRegex = /^[0-9]{10}$/;
export const numberRegex = /^[0-9]{1,5}$/;
export const alphabets = /^[a-zA-Z,\s]*$/;
export const lengthRegex = /^\d*\.?\d{0,2}$/;
export const coodinateRegex =
  /^([-+]?\d{1,3}(?:\.\d{0,6})?),\s([-+]?\d{1,3}(?:\.\d{0,6})?)$/;
export const floatLength = /^(?:[1-9]\d*|0(?!(?:\.0+)?$))?(?:\.\d{2})?$/gm;
export const newCoord = /^([-+]?\d{1,3}(?:\.\d{0,6})?)$/;
export const formatDate = (date) => {
  const NewDate = moment(new Date(date)).format("D MMM YYYY");
  return NewDate;
};
export const alphanumbericRegex = /^[\w\-\s]+$/;
export const alphanumerics = /^[a-zA-Z0-9 ]+$/;
export const formatDateTime = (date) => {
  const dateTime = moment(new Date(date)).format("D MMM YYYY, hh:mm A");
  return dateTime;
};

export const convertDateToISO = (date) => {
  let doo = new Date(date);
  let convertDate = new Date(
    doo.getTime() + Math.abs(doo.getTimezoneOffset() * 60000)
  ).toISOString();
  return convertDate;
};

export const areaRegex = /^\d*\.?\d{1,2}$/;
export const GOOD = "GOOD";
export const MEDIUM_RISK = "MEDIUM_RISK";
export const HIGH_RISK = "HIGH_RISK";
export const MAJOR = "MAJOR";
export const MINOR = "MINOR";

export const severityStatus = {
  GOOD: "Good",
  MEDIUM_RISK: "Medium Risk",
  HIGH_RISK: "High Risk",
  MAJOR: "Major",
  MAJOR: "Minor",
};

export const smeCompleted = "Completed";
